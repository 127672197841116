import {combineReducers} from "redux"
import table, * as fromTable from "../../shared-components/store/temporaries/table/tableReducer"
import menu, * as fromMenu from "../../shared-components/store/temporaries/menu/menuReducer"
import stepBar, * as fromStepBar from "../../shared-components/store/temporaries/stepBar/stepBarReducer"
import page, * as fromPage from "../../shared-components/store/temporaries/page/pageReducer"
import search, * as fromSearch from "../../shared-components/store/temporaries/search/searchReducer"
import siretValidation, * as fromSiretValidation from "../../shared-components/store/temporaries/siretValidation/siretValidationReducer"
import * as fromResetPassword from "./resetPassword/resetPasswordReducer"
import orderDeposit, * as fromOrderDeposit from "./orderDeposit/orderDepositReducer"
import orderDepositHistory, * as fromOrderDepositHistory from "./orderDepositHistory/orderDepositHistoryReducer"
import * as fromBeneficiaries from "./beneficiaries/beneficiariesSceneReducer"
import * as fromManagementRequestsList from "./managementRequestsList/managementRequestsListSceneReducer"
import pagination, * as fromPagination from "../../shared-components/store/temporaries/pagination/paginationReducer"
import marketingOperations from "../../shared-components/store/temporaries/marketingOperations/marketingOperationsReducer"
import * as fromProfileInformation from "./profile/profileReducer"
import participationCard, * as fromParticipationCard from "./participationCard/partcipationCardReducer"
import onlineOrder, * as fromOnlineOrder from "../../shared-components/store/temporaries/onlineOrder/onlineOrderReducer"
import complementaryInformation, * as fromComplementaryInformation from "../../store/temporaries/complementaryInformation/complementaryInformationReducer"
import deliveryPoint, * as fromDeliveryPoint from "../../store/temporaries/deliveryPoint/deliveryPointReducer"
import * as fromOrderHistory from "./orderHistory/orderHistoryReducer"
import * as fromPreOrderSessions from "../../shared-components/store/temporaries/preOrderSessions/preOrderSessionsReducer"
import interlocutorAdministration, * as fromInterlocutorAdministration from "./interlocutorAdministration/interlocutorAdministrationReducer"

/**
 * Part of the store to stock temporary data.
 */
const temporaries = combineReducers({
    complementaryInformation,
    deliveryPoint,
    siretValidation,
    marketingOperations,
    menu,
    interlocutorAdministration,
    onlineOrder,
    orderDeposit,
    orderDepositHistory,
    page,
    pagination,
    participationCard,
    search,
    stepBar,
    table,
    beneficiaries: fromBeneficiaries.beneficiaries(),
    managementRequestsList: fromManagementRequestsList.managementRequestsList(),
    orderHistory: fromOrderHistory.orderHistory(),
    preOrderSessions: fromPreOrderSessions.preOrderSessions(),
    profileInformation: fromProfileInformation.profileInformation(),
    resetPassword: fromResetPassword.resetPassword(),
})

export default temporaries

// MENU OPERATIONS
/**
 * Verify is the menu is responsive
 */
export const isMenuResponsive = state => fromMenu.isResponsive(state.menu)

// STEP BAR OPERATIONS
/**
 * Return the actual step selected in the step bar.
 */
export const getStepBarActualStep = state => fromStepBar.getActualStep(state.stepBar)
/**
 * Return if the steps are clickable
 */
export const isStepsClickable = state => fromStepBar.isStepsClickable(state.stepBar)

// PAGE OPERATIONS
/**
 * Return the actual page.
 */
export const getActualPage = state => fromPage.getActualPage(state.page)
/**
 * Return the previous page.
 */
export const getPreviousPage = state => fromPage.getPreviousPage(state.page)

// TABLE OPERATIONS
/**
 * Return the table data (ids).
 * @param tableName the name of the table
 */
export const getTableDataIds = (state, tableName) => fromTable.getIds(state.table, tableName)
/**
 * Return the ids of the selected lines in a table.
 * @param tableName the name of the table
 */
export const getTableSelectedLinesIds = (state, tableName) => fromTable.getSelectedLines(state.table, tableName)
/**
 * Return the ids of the activated lines in a table.
 * @param tableName the name of the table
 */
export const getTableActivatedLinesIds = (state, tableName) => fromTable.getActivatedLines(state.table, tableName)

/**
 * Return if line ID is activated
 * @param tableName the name of the table
 * @param id the line id to test
 */
export const getTableIsLineIdActivated = (state, tableName, id) => fromTable.isLineActivated(state.table, tableName, id)

/**
 * Return the id of the line in edition in this table or null if there
 * is no line in edition.
 * @param tableName the name of the table
 */
export const getTableLineInEditionId = (state, tableName) => fromTable.getLineInEdition(state.table, tableName)

// SEARCH OPERATIONS
/**
 * Return the results of the town search found in the state.
 */
export const getSearchTownResults = (state, name) => fromSearch.getTownResults(state.search, name)
/**
 * Return the query of the town search found in the state.
 */
export const getSearchTownQuery = (state, name) => fromSearch.getTownQuery(state.search, name)
// SIRET VALIDATION OPERATIONS
/**
 * Return the details of the siret validation.
 */
export const getSiretValidationDetails = state => fromSiretValidation.getDetails(state.siretValidation)

// RESET PASSWORD OPERATIONS
/**
 * Tell if we should display a success message after resetting password
 */
export const getResetPasswordDisplaySuccessMessage = state =>
    fromResetPassword.getResetPasswordDisplaySuccessMessage(state.resetPassword)

export const getPasswordScore = state => fromResetPassword.getPasswordScore(state.resetPassword)

// ORDER DEPOSIT OPERATIONS
/**
 * Tell if we should display a success message after creating an order deposit
 */
export const getOrderDepositDisplaySuccessMessage = state =>
    fromOrderDeposit.getDisplaySuccessMessage(state.orderDeposit)

/**
 * Return pagination information about order deposit history
 */
export const getOrderDepositHistoryPagination = state =>
    fromOrderDepositHistory.getPagination(state.orderDepositHistory)

/**
 * Return the active page for component
 */
export const getActivePage = (state, componentName) => fromPagination.getActivePage(state.pagination, componentName)
/**
 * Return the max page for a component
 */
export const getMaxPage = (state, componentName) => fromPagination.getMaxPage(state.pagination, componentName)

/**
 * Return the total results for a component
 */
export const getTotalResults = (state, componentName) => fromPagination.getTotalResults(state.pagination, componentName)

// PROFILE INFORMATION OPERATIONS
/**
 * Return profile informations
 */
export const getProfileInformationValues = state =>
    state.profileInformation ? fromProfileInformation.getValues(state.profileInformation) : undefined

/**
 * Tell if we should display a success message after updating profile informations
 */
export const getUpdateProfileInformationDisplaySuccessMessage = state =>
    fromProfileInformation.getUpdateProfileInformationDisplaySuccessMessage(state.profileInformation)

// PARTICIPATION CARD OPERATIONS
/**
 * Get participation card used staff categories
 */
export const getParticipationCardUsedStaffCategories = state =>
    fromParticipationCard.getUsedStaffCategories(state.participationCard)

// ONLINE ORDER OPERATIONS
/**
 * Get all values from the online order.
 */
export const getOnlineOrderValues = state => fromOnlineOrder.getValues(state.onlineOrder)

// BENEFICIARIES OPERATIONS
/**
 * Return pagination information about beneficiaries
 */
export const getBeneficiariesPagination = state => fromBeneficiaries.getPagination(state.beneficiaries)

/**
 * Return if we should show details of selected beneficiary
 */
export const getBeneficiariesViewDetails = state => fromBeneficiaries.getViewDetails(state.beneficiaries)

/**
 * Return value of search params of beneficiaries
 */
export const getBeneficiariesSearchParams = state => fromBeneficiaries.getSearchParams(state.beneficiaries)

// MANAGEMENT REQUESTS OPERATIONS
/**
 * Return pagination information about management requests
 */
export const getManagementRequestsListPagination = state =>
    fromManagementRequestsList.getPagination(state.managementRequestsList)

/**
 * Return value of search params of management requests
 */
export const getManagementRequestsListSearchParams = state =>
    fromManagementRequestsList.getSearchParams(state.managementRequestsList)

// ORDERS HISTORY OPERATIONS
/**
 * Return pagination information about order history
 */
export const getOrderHistoryPagination = state => fromOrderHistory.getPagination(state.orderHistory)

/**
 * Return value of search params of order history
 */
export const getOrderHistorySearchParams = state => fromOrderHistory.getSearchParams(state.orderHistory)

/**
 * Return order from the store in order to view details
 */
export const getOrderViewDetails = state => fromOrderHistory.getViewDetails(state.orderHistory)

/**
 * Return pagination information about order history order's lines
 */
export const getOrderHistoryOrderLinesPagination = state => fromOrderHistory.getOrderLinesPagination(state.orderHistory)

/**
 * Return value of search params of order history order's lines
 */
export const getOrderHistoryOrderLinesSearchParams = state =>
    fromOrderHistory.getOrderLinesSearchParams(state.orderHistory)

/**
 * Return complementary information from preOrderSessionControl
 */
export const getComplementaryInformation = (state, id) =>
    fromComplementaryInformation.getComplementaryInformation(state.complementaryInformation, id)

/**
 * Return a delivery point from preOrderSessionDelivery
 */
export const getDeliveryPoint = (state, id) => fromDeliveryPoint.getDeliveryPoint(state.deliveryPoint, id)

// PREORDER SESSIONS
/**
 * Find the statistics of a preorder session.
 */
export const getPreOrderSessionStatisticsById = (state, sessionId) =>
    fromPreOrderSessions.getStatisticsById(state.preOrderSessions, sessionId)

/**
 * Find the orders linked to a preorder session.
 */
export const getPreOrderSessionOrdersById = (state, sessionId) =>
    fromPreOrderSessions.getOrdersById(state.preOrderSessions, sessionId)

/**
 * Return preorder session pagination lines.
 */
export const getPreOrderSessionPaginationLines = state =>
    fromPreOrderSessions.getPaginationLines(state.preOrderSessions)

/**
 * Return preorder session values of search lines params.
 */
export const getPreOrderSessionSearchLinesParams = state =>
    fromPreOrderSessions.getSearchLinesParams(state.preOrderSessions)

/**
 * Return the selected interlocutor id
 */
export const getInterlocutorId = state =>
    fromInterlocutorAdministration.getInterlocutorId(state.interlocutorAdministration)

/**
 * Return the display interlocutor details
 */
export const getDisplayInterlocutorDetails = state =>
    fromInterlocutorAdministration.getDisplayInterlocutorDetails(state.interlocutorAdministration)
