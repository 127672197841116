/**
 * Indicate that we want to save a new SIRET value.
 * @type {string}
 */
export const SAVE_SIRET_VALUE = "SAVE_SIRET_VALUE";

/**
 * Indicate that we want to clear the SIRET value.
 * @type {string}
 */
export const CLEAR_SIRET_VALUE = "CLEAR_SIRET_VALUE";

/**
 * Indicate that we want to save contact information values except the SIRET value.
 * @type {string}
 */
export const SAVE_CONTACT_INFORMATION_VALUES = "SAVE_CONTACT_INFORMATION_VALUES";

/**
 * Indicate that we want to clear all contact information values except the SIRET value.
 * @type {string}
 */
export const CLEAR_CONTACT_INFORMATION_VALUES = "CLEAR_CONTACT_INFORMATION_VALUES";
