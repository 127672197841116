/**
 * Indicate that we want to add a new beneficiary.
 * @type {string}
 */
export const ADD_NEW_BENEFICIARY = "ADD_NEW_BENEFICIARY";

/**
 * Indicate that we want to remove a beneficiary.
 * @type {string}
 */
export const REMOVE_BENEFICIARY = "REMOVE_BENEFICIARY";

/**
 * Indicate that we want to edit a beneficiary.
 * @type {string}
 */
export const EDIT_BENEFICIARY = "EDIT_BENEFICIARY";

/**
 * Indicate that we want to update a beneficiary.
 * @type {string}
 */
export const UPDATE_BENEFICIARY = "UPDATE_BENEFICIARY";

/**
 * Indicate that we want to clear the beneficiary in edition.
 * @type {string}
 */
export const CLEAR_EDIT_BENEFICIARY = "CLEAR_EDIT_BENEFICIARY";

/**
 * Indicate that we want to toggle the selection of a beneficiary.
 * @type {string}
 */
export const TOGGLE_SELECTION_BENEFICIARY = "TOGGLE_SELECTION_BENEFICIARY";

/**
 * Indicate that we want to select all chosen beneficiaries.
 * @type {string}
 */
export const SELECT_ALL_CHOSEN = "SELECT_ALL_CHOSEN";

/**
 * Indicate that we want to deselect all chosen beneficiaries.
 * @type {string}
 */
export const DESELECT_ALL_CHOSEN = "DESELECT_ALL_CHOSEN";

/**
 * Indicate that we want to select all not chosen beneficiaries.
 * @type {string}
 */
export const SELECT_ALL_NOT_CHOSEN = "SELECT_ALL_NOT_CHOSEN";

/**
 * Indicate that we want to deselect all not chosen beneficiaries.
 * @type {string}
 */
export const DESELECT_ALL_NOT_CHOSEN = "DESELECT_ALL_NOT_CHOSEN";

/**
 * Indicate that we want to choose beneficiaries for order.
 * @type {string}
 */
export const CHOOSE_BENEFICIARIES_FOR_ORDER = "CHOOSE_BENEFICIARIES_FOR_ORDER";

/**
 * Indicate that we want to reject beneficiaries of order.
 * @type {string}
 */
export const REJECT_BENEFICIARIES_OF_ORDER = "REJECT_BENEFICIARIES_OF_ORDER";

/**
 * Indicate that we want to empty the beneficiary order.
 * @type {string}
 */
export const EMPTY_BENEFICIAIRES_ORDER = "EMPTY_BENEFICIAIRES_ORDER";
