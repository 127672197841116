/**
 * Indicate that we want to save values of params for beneficiaries search
 * @type {string}
 */
export const SAVE_BENEFICIARIES_SEARCH_PARAMS = "SAVE_BENEFICIARIES_SEARCH_PARAMS";

/**
 * Indicate that we want to clear values of params for beneficiaries search
 * @type {string}
 */
export const CLEAR_BENEFICIARIES_SEARCH_PARAMS = "CLEAR_BENEFICIARIES_SEARCH_PARAMS";

/**
 * Indicate that we want to clear values of pagination for beneficiaries table
 * @type {string}
 */
export const CLEAR_BENEFICIARIES_PAGINATION = "CLEAR_BENEFICIARIES_PAGINATION";
