import * as fromRequests from "../../shared-components/store/requests/requestsReducer";
import {RequestKeys, RequestInfo} from "../../shared-components/constants/requests";

/**
 * Verify if change password request is fetching
 */
export const isFetchingChangePassword = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.CHANGE_PASSWORD].name, state.requests);

/**
 * Return the change password request error message
 */
export const getChangePasswordErrorMessage = state =>
    fromRequests.getErrorMessage(RequestInfo[RequestKeys.CHANGE_PASSWORD].name, state.requests);
