/**
 * Indicate that we want to save the values for the confirmation page.
 * @type {string}
 */
export const SAVE_CONFIRMATION_VALUES = "SAVE_CONFIRMATION_VALUES"

/**
 * Indicate that we want to clear all values for the confirmation page.
 * @type {string}
 */
export const CLEAR_CONFIRMATION_VALUES = "CLEAR_CONFIRMATION_VALUES"
