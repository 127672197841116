import {combineReducers} from "redux"
import {
    RETRIEVE_MANAGEMENT_REQUESTS_FAILURE,
    RETRIEVE_MANAGEMENT_REQUESTS_REQUEST,
    RETRIEVE_MANAGEMENT_REQUESTS_SUCCESS,
} from "../../../shared-components/actions/entities/managementRequests/managementRequestsActionTypes"
import {
    CLEAR_MANAGEMENT_REQUESTS_PAGINATION,
    CLEAR_MANAGEMENT_REQUESTS_SEARCH_PARAMS,
    SAVE_MANAGEMENT_REQUESTS_SEARCH_PARAMS,
} from "../../../actions/temporaries/managementRequestsList/managementRequestsListActionsTypes"

/**
 * Save pagination for management requests into the temporaries branch into the store.
 */
export const managementRequestsList = () => {
    const pagination = (state = {}, action = {}) => {
        switch (action.type) {
            case RETRIEVE_MANAGEMENT_REQUESTS_SUCCESS:
                return action.pagination ? action.pagination : {}

            case RETRIEVE_MANAGEMENT_REQUESTS_REQUEST:
            case RETRIEVE_MANAGEMENT_REQUESTS_FAILURE:
            case CLEAR_MANAGEMENT_REQUESTS_PAGINATION:
                return {}

            default:
                return state
        }
    }

    const searchParams = (state = {}, action = {}) => {
        switch (action.type) {
            case SAVE_MANAGEMENT_REQUESTS_SEARCH_PARAMS:
                return action.formValues

            case CLEAR_MANAGEMENT_REQUESTS_SEARCH_PARAMS:
                return {}

            default:
                return state
        }
    }

    return combineReducers({
        pagination,
        searchParams,
    })
}

/**
 * Return pagination details.
 */
export const getPagination = state => state.pagination

/**
 * Return values of search params.
 */
export const getSearchParams = state => state.searchParams
