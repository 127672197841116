import {
    VERIFY_MARKETING_OPERATION_FAILURE,
    VERIFY_MARKETING_OPERATION_REQUEST,
    VERIFY_MARKETING_OPERATION_SUCCESS,
} from "../../../actions/entities/marketingOperations/marketingOperationsActionTypes";

/**
 * When data on marketing operations are retrieve from the API,
 * save them in the store.
 */
const marketingOperations = (state = {}, action = {}) => {
    switch (action.type) {
        case VERIFY_MARKETING_OPERATION_SUCCESS:
            return {
                verified: action.response.valeur,
            };

        case VERIFY_MARKETING_OPERATION_FAILURE:
        case VERIFY_MARKETING_OPERATION_REQUEST:
            return {};

        default:
            return state;
    }
};

export default marketingOperations;

/**
 * Indicate if the marketing operation is verified.
 */
export const isVerified = state => state.verified;
