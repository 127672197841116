import {
    CREATE_ORDER_DEPOSIT_SUCCESS,
    CREATE_ORDER_DEPOSIT_REQUEST,
    CREATE_ORDER_DEPOSIT_FAILURE,
    CLEAR_ORDER_DEPOSIT_REQUEST,
} from "../../../shared-components/actions/entities/orderDeposits/orderDepositActionTypes";

const orderDeposit = (state = {displaySuccessMessage: false}, action = {}) => {
    switch (action.type) {
        case CREATE_ORDER_DEPOSIT_SUCCESS:
            return {
                displaySuccessMessage: true,
            };
        case CREATE_ORDER_DEPOSIT_FAILURE:
        case CREATE_ORDER_DEPOSIT_REQUEST:
        case CLEAR_ORDER_DEPOSIT_REQUEST:
            return {
                displaySuccessMessage: false,
            };

        default:
            return state;
    }
};

export default orderDeposit;

/**
 * Tell if we should display a success message after creating order deposit
 */
export const getDisplaySuccessMessage = state => state.displaySuccessMessage;
