/**
 * Save PreOrder participation values
 */
export const SAVE_PARTICIPATION_VALUES = "SAVE_PARTICIPATION_VALUES"

/**
 * Clear PreOrder participation values
 */
export const CLEAR_PARTICIPATION_VALUES = "CLEAR_PARTICIPATION_VALUES"

/**
 * Remove a Preorder participation value
 */
export const REMOVE_PARTICIPATION_VALUE = "REMOVE_PARTICIPATION_VALUE"
