import {
    CLEAR_INIT_VALUES,
    SAVE_INIT_VALUES,
} from "../../../actions/localStorage/preOrderSessionInit/preOrderSessionInitActionTypes"
import {PREORDER_SESSION_INFORMATION_SUCCESS} from "../../../shared-components/actions/entities/preOrderSessions/preOrderSessionsActionTypes"

const preOrderSessionInit = (state = {}, action = {}) => {
    switch (action.type) {
        case PREORDER_SESSION_INFORMATION_SUCCESS:
        case SAVE_INIT_VALUES:
            return {
                id: action.id,
                label: action.label,
                beginDate: action.beginDate,
                endDate: action.endDate,
                permanent: action.permanent,
                accountProduct: action.accountProduct,
                checkbookProduct: action.checkbookProduct,
                customLabelAccountProduct: action.customLabelAccountProduct,
                customLabelCheckbookProduct: action.customLabelCheckbookProduct,
                facialValue: action.facialValue,
                checkbookCheckNumberRestrictions: action.checkbookCheckNumberRestrictions,
                serviceType: action.serviceType,
            }
        case CLEAR_INIT_VALUES:
            return {}
        default:
            return state
    }
}

export default preOrderSessionInit

export const getPreOrderSessionInit = state => state
