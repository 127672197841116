import {union, without} from "lodash";
import {
    ADD_USED_STAFF_CATEGORY,
    REMOVE_USED_STAFF_CATEGORY,
} from "../../../actions/temporaries/participationCard/participationCardActionTypes";

const participationCard = (state = [], action = {}) => {
    switch (action.type) {
        case ADD_USED_STAFF_CATEGORY:
            return union(state, [action.staffCategory]);
        case REMOVE_USED_STAFF_CATEGORY:
            return without(state, action.staffCategory);
        default:
            return state;
    }
};

export default participationCard;

/**
 * Get used staff categories
 */
export const getUsedStaffCategories = state => state;
