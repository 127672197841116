import {
    GET_ONLINE_ORDER_RESULTS_FAILURE,
    GET_ONLINE_ORDER_RESULTS_REQUEST,
    GET_ONLINE_ORDER_RESULTS_SUCCESS, SAVE_MAIL_IN_ONLINE_ORDER_RESULTS,
    SAVE_ONLINE_ORDER_FAILURE,
    SAVE_ONLINE_ORDER_REQUEST, SAVE_ONLINE_ORDER_SUCCESS
} from "../../../actions/temporaries/onlineOrder/onlineOrderActionTypes";

/**
 * Online order reducer.
 */
const onlineOrder = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ONLINE_ORDER_SUCCESS:
        case GET_ONLINE_ORDER_RESULTS_SUCCESS:
            return {
                titleOrderNumber: action.response.numeroCommandeTitre,
                accountOrderNumber: action.response.numeroCommandeCompte,
                clientNumber: action.response.numeroClient,
                contractNumber: action.response.numeroContrat,
                mail: action.response.email,
            };
        case SAVE_MAIL_IN_ONLINE_ORDER_RESULTS:
            return {
                ...state,
                mail: action.mail,
            };
        case SAVE_ONLINE_ORDER_FAILURE:
        case GET_ONLINE_ORDER_RESULTS_FAILURE:
        case SAVE_ONLINE_ORDER_REQUEST:
        case GET_ONLINE_ORDER_RESULTS_REQUEST:
            return {};
        default:
            return state;
    }
};

export default onlineOrder;

/**
 * Return the values stored in the store.
 */
export const getValues = (state) => state;
