import {
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
} from "../../../shared-components/actions/sessionStorage/user/userActionTypes"
import {combineReducers} from "redux"
import {
    CLEAR_PASSWORD_SCORE,
    SAVE_PASSWORD_SCORE,
} from "../../../shared-components/actions/temporaries/resetPassword/resetPasswordActionTypes"

export const resetPassword = () => {
    const request = (state = {displaySuccessMessage: false}, action = {}) => {
        switch (action.type) {
            case CHANGE_PASSWORD_SUCCESS:
                return {
                    displaySuccessMessage: true,
                }
            case CHANGE_PASSWORD_FAILURE:
            case CHANGE_PASSWORD_REQUEST:
                return {
                    displaySuccessMessage: false,
                }
            default:
                return state
        }
    }

    const password = (state = {score: 0}, action = {}) => {
        switch (action.type) {
            case SAVE_PASSWORD_SCORE:
                return {
                    score: action.passwordScore,
                }

            case CLEAR_PASSWORD_SCORE:
                return {
                    score: 0,
                }

            default:
                return state
        }
    }

    return combineReducers({
        request,
        password,
    })
}

/**
 * Tell if we should display a success message after resetting password
 */
export const getResetPasswordDisplaySuccessMessage = state => state.request.displaySuccessMessage

/**
 * Return password score
 */
export const getPasswordScore = state => state.password.score
