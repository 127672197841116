import {
    SELECT_INTERLOCUTOR,
    DISPLAY_INTERLOCUTOR_DETAILS,
} from "../../../actions/temporaries/interlocutorAdministration/interlocutorAdministrationActionTypes"

const interlocutorAdministration = (state = {displayInterlocutorDetails: false}, action = {}) => {
    switch (action.type) {
        case SELECT_INTERLOCUTOR:
            return {
                ...state,
                interlocutorId: action.interlocutorId,
            }
        case DISPLAY_INTERLOCUTOR_DETAILS:
            return {
                ...state,
                displayInterlocutorDetails: action.display,
            }
        default:
            return state
    }
}

export default interlocutorAdministration

/**
 * Get the selected interlocutor id
 */
export const getInterlocutorId = state => state.interlocutorId

/**
 * Get display interlocutor details
 */
export const getDisplayInterlocutorDetails = state => state.displayInterlocutorDetails
