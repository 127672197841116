import {
    CLEAR_PROFILE_INFORMATION_VALUES,
    RETRIEVE_PROFILE_INFORMATION_VALUES_FAILURE,
    RETRIEVE_PROFILE_INFORMATION_VALUES_REQUEST,
    RETRIEVE_PROFILE_INFORMATION_VALUES_SUCCESS,
    UPDATE_PROFILE_INFORMATION_VALUES_FAILURE,
    UPDATE_PROFILE_INFORMATION_VALUES_REQUEST,
    UPDATE_PROFILE_INFORMATION_VALUES_SUCCESS,
} from "../../../shared-components/actions/temporaries/profile/profileActionTypes";
import {combineReducers} from "redux";

/**
 * Save profile information values into the store.
 */
export const profileInformation = () => {
    const get = (state = {}, action = {}) => {
        switch (action.type) {
            case RETRIEVE_PROFILE_INFORMATION_VALUES_SUCCESS:
                return action.response;

            case RETRIEVE_PROFILE_INFORMATION_VALUES_REQUEST:
            case RETRIEVE_PROFILE_INFORMATION_VALUES_FAILURE:
            case CLEAR_PROFILE_INFORMATION_VALUES:
                return {};

            default:
                return state;
        }
    };

    const update = (state = {displaySuccessMessage: false}, action = {}) => {
        switch (action.type) {
            case UPDATE_PROFILE_INFORMATION_VALUES_SUCCESS:
                return {
                    displaySuccessMessage: true,
                };

            case UPDATE_PROFILE_INFORMATION_VALUES_FAILURE:
            case UPDATE_PROFILE_INFORMATION_VALUES_REQUEST:
            case CLEAR_PROFILE_INFORMATION_VALUES:
                return {
                    displaySuccessMessage: false,
                };

            default:
                return state;
        }
    };

    return combineReducers({
        get,
        update,
    });
};

/**
 * Return the profile information values
 */
export const getValues = state => state.get;

/**
 * Tell if we should display a success message after updating profile informations
 */
export const getUpdateProfileInformationDisplaySuccessMessage = state => state.update.displaySuccessMessage;
