import {omit} from "lodash"
import {
    ADD_COMPLEMENTARY_INFORMATION,
    REMOVE_COMPLEMENTARY_INFORMATION,
} from "../../../actions/temporaries/complementaryInformation/complementaryInformationActionTypes"

const complementaryInformation = (state = {}, action = {}) => {
    switch (action.type) {
        case ADD_COMPLEMENTARY_INFORMATION:
            return {
                ...state,
                [action.id]: {
                    code: action.code,
                    label: action.label,
                    id: action.id,
                    controlId: action.controlId,
                },
            }
        case REMOVE_COMPLEMENTARY_INFORMATION:
            return omit(state, action.id)
        default:
            return state
    }
}

export default complementaryInformation

/**
 * Get complementary information
 */
export const getComplementaryInformation = (state, id) => state[id]
