import {omit} from "lodash";
import {CLEAR_ORDERS, REMOVE_ORDER, SAVE_ORDERS} from "../../../actions/localStorage/order/orderActionTypes";
import {GET_DUPLICATION_INFORMATION_SUCCESS} from "../../../shared-components/actions/temporaries/onlineOrder/onlineOrderActionTypes";

/**
 * Save orders values into the store.
 * Can be empty.
 * Structure :
 * - preferentialCode : the preferential code
 * - beneficiariesOrders : the orders
 */
const order = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ORDERS:
            return {
                ...omit(action, ["type"]),
            };

        case REMOVE_ORDER:
            return {
                ...state,
                beneficiariesOrders: omit(state.beneficiariesOrders, [action.id]),
            };

        case CLEAR_ORDERS:
            return {};

        case GET_DUPLICATION_INFORMATION_SUCCESS:
            return {
                beneficiariesOrders: action.beneficiariesOrders,
            };
        default:
            return state;
    }
};

export default order;

/**
 * Get the preferential code.
 */
export const getPreferentialCode = state => state.preferentialCode;

/**
 * Get the beneficiaries orders.
 */
export const getBeneficiariesOrders = state => state.beneficiariesOrders;
