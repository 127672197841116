import {Page} from "../pages"
import {PagesArchitecture} from "../pagesArchitecture"
import {createItem, createLink, createDropdown} from "../../shared-components/common/utils/menu/menu"

/**
 * Return the menu
 * @param  {[type]} actualPage page where the user is
 * @param  {[type]} history    history function for navigation
 */
export const menuContent = (
    actualPage,
    history,
    clearPreOrderSessionValues,
    isClientFromCollectivity,
    isClientFromPetitPro,
) => {
    const orderExcelChoices = [
        createItem(actualPage, history, [], Page.ORDER_DEPOSIT, PagesArchitecture),
        createItem(actualPage, history, [], Page.ORDER_DEPOSIT_HISTORY, PagesArchitecture),
    ]

    const preOrderSessionChoices = [
        createItem(
            actualPage,
            history,
            [],
            Page.PREORDER_SESSION_INIT,
            PagesArchitecture,
            "Créer une nouvelle session",
            () => {
                clearPreOrderSessionValues()
                history.push(PagesArchitecture[Page.PREORDER_SESSION_INIT].route)
            },
        ),
        createItem(actualPage, history, [], Page.PREORDER_SESSION_MANAGEMENT, PagesArchitecture),
    ]

    const orderChoices = [
        createItem(actualPage, history, [], Page.ORDER_BENEFICIARIES, PagesArchitecture),
        createDropdown("Commande Excel", true, orderExcelChoices),
        createDropdown("Sessions de précommande", true, preOrderSessionChoices),
        createItem(actualPage, history, [], Page.ORDER_HISTORY, PagesArchitecture),
    ]

    const forceHelpDisplay =
        actualPage == "ORDER_SIRET_INFORMATION" ||
        actualPage == "ORDER_CONTACT_INFORMATION" ||
        actualPage == "ORDER_BENEFICIARIES" ||
        actualPage == "ORDER_ORDER" ||
        actualPage == "ORDER_OVERVIEW" ||
        actualPage == "ORDER_PAYMENT" ||
        actualPage == "ORDER_CONFIRMATION"

    return [
        createDropdown("Commande", true, orderChoices, "RIGHT"),
        createItem(
            actualPage,
            history,
            [],
            isClientFromCollectivity ? Page.RECIPIENTS : Page.BENEFICIARIES,
            PagesArchitecture,
        ),
        isClientFromPetitPro
            ? createLink(actualPage, history, [], Page.ASK_FOR_HELP_CONNECTED, PagesArchitecture)
            : null,
        createDropdown(
            "Statistiques",
            true,
            [
                createItem(actualPage, history, [], Page.GENERAL_DATA, PagesArchitecture),
                createItem(actualPage, history, [], Page.INTERVENANT_SEARCH, PagesArchitecture),
            ],
            "RIGHT",
        ),
        createDropdown(
            "Documents",
            true,
            [createItem(actualPage, history, [], Page.REPORTS, PagesArchitecture)],
            "RIGHT",
        ),
        createDropdown(
            "Tuteurs",
            true,
            [
                createItem(actualPage, history, [], Page.INTERLOCUTOR_ADMINISTRATION, PagesArchitecture),
                createItem(actualPage, history, [], Page.INTERLOCUTOR_ACCOUNT_ACCESS, PagesArchitecture),
            ],
            "LEFT",
        ),
        createDropdown(
            "Contact",
            true,
            [
                createItem(
                    actualPage,
                    history,
                    [],
                    Page.MANAGEMENT_REQUESTS_LIST,
                    PagesArchitecture,
                    "DEMANDES DE GESTION",
                ),
            ],
            "LEFT",
        ),
        createItem(actualPage, history, [], Page.LOGIN, PagesArchitecture),
        createItem(actualPage, history, [], Page.ORDER_SIRET_INFORMATION, PagesArchitecture, "INSCRIPTION"),
        forceHelpDisplay ? createLink(actualPage, history, [], Page.ASK_FOR_HELP, PagesArchitecture) : null,
    ].filter(item => item !== null)
}
