import {omit} from "lodash";
import {
    ADD_DELIVERY_POINT,
    REMOVE_DELIVERY_POINT,
} from "../../../actions/temporaries/deliveryPoint/deliveryPointActionTypes";

const deliveryPoint = (state = {}, action = {}) => {
    switch (action.type) {
        case ADD_DELIVERY_POINT:
            return {
                ...state,
                [action.id]: {
                    id: action.id,
                },
            };
        case REMOVE_DELIVERY_POINT:
            return omit(state, action.id);
        default:
            return state;
    }
};

export default deliveryPoint;

/**
 * Get delivery point
 */
export const getDeliveryPoint = (state, id) => state[id];
