import {CLEAR_CONTROL_VALUES, SAVE_CONTROL_VALUES} from "./preOrderSessionControlActionTypes";

export const saveControlValues = values => ({
    type: SAVE_CONTROL_VALUES,
    values,
});

export const clearControlValues = () => ({
    type: CLEAR_CONTROL_VALUES,
});
