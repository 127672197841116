import * as fromSessionStorage from "../../sessionStorage/sessionStorageReducer"
import * as fromRequests from "../../../shared-components/store/requests/requestsReducer"
import {RequestKeys, RequestInfo} from "../../../shared-components/constants/requests"
import {SegmentTypes} from "../../../shared-components/constants/segmentTypes"
import {ClientType} from "../../../constants/clientType"

/**
 * Get the user token from the state.
 */
export const getUserToken = state => fromSessionStorage.getUserToken(state.sessionStorage)

/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromSessionStorage.getExpirationDate(state.sessionStorage)

/**
 * Verify is user is connected.
 */
export const isUserConnected = state => !!getUserToken(state)

/**
 * Verify if user token is fetching.
 */
export const isFetchingAuthority = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.USER_AUTHORITY].name, state.requests)

/**
 * Get the authority error message from the state.
 */
export const getAuthorityErrorMessage = state =>
    fromRequests.getErrorMessage(RequestInfo[RequestKeys.USER_AUTHORITY].name, state.requests)

/**
 * Get user permissions from the store.
 */
export const getUserPermissions = state => fromSessionStorage.getUserPermissions(state.sessionStorage)

/**
 * Get user identity from the store.
 */
export const getUserDetails = state => fromSessionStorage.getUserDetails(state.sessionStorage)

/**
 * Get user client type from the store.
 */
export const getUserClientType = state => getUserDetails(state).clientType

/**
 * Get user client ID from the store.
 */
export const getUserClientId = state => getUserDetails(state).clientId

/**
 * Get user client number from the store.
 */
export const getUserClientNumber = state => getUserDetails(state).clientNumber

/**
 * Get user login from the store.
 */
export const getUserLogin = state => getUserDetails(state).login

/**
 * Get user siret from the store.
 */
export const getUserSiret = state => getUserDetails(state).siret

/**
 * Get the user full name
 */
export const getUserFullname = state => getUserDetails(state).firstName + " " + getUserDetails(state).lastName

/**
 * Get user client type from the store.
 */
export const getUserSegmentId = state => getUserDetails(state).segmentId

/**
 * Get user client type from the store.
 */
export const isUserFromCollectivity = state => getUserDetails(state).segmentId === SegmentTypes.COLLECTIVITE

/**
 * Get user client type from the store.
 */
export const isUserFromPetitPro = state =>
    getUserDetails(state).segmentId === SegmentTypes.PROFESSION_LIBERALE ||
    getUserDetails(state).segmentId === SegmentTypes.PME ||
    getUserDetails(state).segmentId === SegmentTypes.TPE

/**
 * Tell if the user a big client
 */
export const isBigClient = state => getUserClientType(state) === ClientType.GRAND_CLIENT

/**
 * Get user social reason from the store
 */
export const getUserSocialReason = state => getUserDetails(state).socialReason

/**
 * Verify if user details is fetching ?
 */
export const isFetchingUserDetails = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.USER_INFO].name, state.requests)

/**
 * Verifiy is recover login password request is fetching
 */
export const isFetchingRecoverLoginPassword = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.RECOVER_LOGIN_PASSWORD].name, state.requests)
