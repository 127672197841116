import {combineReducers} from "redux"
import {
    CLEAR_CONTROL_VALUES,
    SAVE_CONTROL_VALUES,
} from "../../../actions/localStorage/preOrderSessionControl/preOrderSessionControlActionTypes"
import {
    VALIDATE_CONTROL_FILE_REQUEST,
    VALIDATE_CONTROL_FILE_SUCCESS,
    VALIDATE_CONTROL_FILE_FAILURE,
} from "../../../shared-components/actions/entities/preOrderSessions/preOrderSessionsActionTypes"
import {PREORDER_SESSION_INFORMATION_SUCCESS} from "../../../shared-components/actions/entities/preOrderSessions/preOrderSessionsActionTypes"

const preOrderSessionControl = (state = {}, action = {}) => {
    const saveControlValues = (state = {}, action = {}) => {
        switch (action.type) {
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.controlValues
            case SAVE_CONTROL_VALUES:
                return action.values
            case CLEAR_CONTROL_VALUES:
                return {}
            default:
                return state
        }
    }

    const fields = (state = {}, action = {}) => {
        switch (action.type) {
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.fields
            case VALIDATE_CONTROL_FILE_SUCCESS:
                return action.fields
            case CLEAR_CONTROL_VALUES:
            case VALIDATE_CONTROL_FILE_REQUEST:
            case VALIDATE_CONTROL_FILE_FAILURE:
                return {}
            default:
                return state
        }
    }

    const filePath = (state = {}, action = {}) => {
        switch (action.type) {
            case VALIDATE_CONTROL_FILE_SUCCESS:
                return action.filePath
            case CLEAR_CONTROL_VALUES:
                return {}
            default:
                return state
        }
    }

    return combineReducers({
        fields,
        filePath,
        saveControlValues,
    })
}

export default preOrderSessionControl

export const getPreOrderSessionControl = state => state.saveControlValues

export const getFields = state => state.fields

export const getFilePath = state => state.filePath
