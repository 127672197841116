import {
    CLEAR_CONFIRMATION_VALUES,
    SAVE_CONFIRMATION_VALUES,
} from "../../../actions/localStorage/confirmation/confirmationActionTypes"

/**
 * Save confirmation values into the store.
 * Can be empty.
 */
const confirmation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CONFIRMATION_VALUES:
            return {
                withTitleOrder: action.withTitleOrder,
                withAccountOrder: action.withAccountOrder,
                isCreditCardPayement: action.isCreditCardPayement,
                isVirementPayement: action.isVirementPayement,
            }
        case CLEAR_CONFIRMATION_VALUES:
            return {}
        default:
            return state
    }
}

export default confirmation

/**
 * Return the confirmation values.
 */
export const getValues = state => state
