import {
    CLEAR_CONTACT_INFORMATION_VALUES,
    CLEAR_SIRET_VALUE,
    SAVE_CONTACT_INFORMATION_VALUES,
    SAVE_SIRET_VALUE,
} from "../../../actions/localStorage/contactInformation/contactInformationActionTypes"
import {omit} from "lodash"

/**
 * Save contact information values into the store.
 * Can be empty.
 */
const contactInformation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_SIRET_VALUE:
            return {
                ...state,
                siret: action.siret,
            }

        case CLEAR_SIRET_VALUE:
            return omit(state, ["siret"])

        case SAVE_CONTACT_INFORMATION_VALUES:
            return {
                ...state,
                civility: action.civility,
                name: action.name,
                firstName: action.firstName,
                mail: action.mail,
                phoneNumber: action.phoneNumber,
                mobileNumber: action.mobileNumber,
                socialReason: action.socialReason,
                apeCode: action.apeCode,
                effective: action.effective,
                segmentId: action.segmentId,
                recipient: action.recipient,
                recipientComplement: action.recipientComplement,
                address: action.address,
                addressComplement: action.addressComplement,
                postalCodeTown: action.postalCodeTown,
                societyPhoneNumber: action.societyPhoneNumber,
                societyMail: action.societyMail,
            }

        case CLEAR_CONTACT_INFORMATION_VALUES:
            return omit(state, [
                "civility",
                "name",
                "firstName",
                "mail",
                "phoneNumber",
                "mobileNumber",
                "socialReason",
                "apeCode",
                "effective",
                "segmentId",
                "recipient",
                "recipientComplement",
                "address",
                "addressComplement",
                "postalCodeTown",
                "societyPhoneNumber",
                "societyMail",
            ])

        default:
            return state
    }
}

export default contactInformation

/**
 * Return the SIRET.
 */
export const getSiret = state => state.siret

/**
 * Return the contact information values.
 */
export const getValues = state => state
