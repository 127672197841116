/**
 * Indicate that we want to save the orders.
 * @type {string}
 */
export const SAVE_ORDERS = "SAVE_ORDERS";

/**
 * Indicate that we want to remove an order.
 * @type {string}
 */
export const REMOVE_ORDER = "REMOVE_ORDER";

/**
 * Indicate that we want to clear all orders.
 * @type {string}
 */
export const CLEAR_ORDERS = "CLEAR_ORDERS";
