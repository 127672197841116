import PropTypes from "prop-types"
import React, {Component, Suspense} from "react"
import {connect} from "react-redux"
import {Redirect, Route, Router, Switch, withRouter} from "react-router-dom"
import {initialize} from "redux-form"
import {clearCommunicationValues} from "../actions/localStorage/preOrderSessionCommunication/preOrderSessionCommunicationActions"
import {clearControlValues} from "../actions/localStorage/preOrderSessionControl/preOrderSessionControlActions"
import {clearDeliveryValues} from "../actions/localStorage/preOrderSessionDelivery/preOrderSessionDeliveryActions"
import {clearPreOrderInitValues} from "../actions/localStorage/preOrderSessionInit/preOrderSessionInitActions"
import {clearPreOrderParticipationValues} from "../actions/localStorage/preOrderSessionParticipation/preOrderSessionParticipationActions"
import {AREA_TYPE} from "../constants/areaType"
import {ClientType} from "../constants/clientType"
import {menuContent} from "../constants/menu/menu"
import {Page} from "../constants/pages"
import {PagesArchitecture} from "../constants/pagesArchitecture"
import history from "../history"
import {saveCookiesChoices} from "../shared-components/actions/localStorage/cookies/cookiesActions"
import {disconnectUser} from "../shared-components/actions/sessionStorage/user/userActions"
import {setResponsive} from "../shared-components/actions/temporaries/menu/menuAction"
import {changeActualPage} from "../shared-components/actions/temporaries/page/pageActions"
import {logoutUser} from "../shared-components/common/actions/user/userActions"
import CookiesNotificationContainer from "../shared-components/components/CookiesNotification/cookiesNotificationContainer"
import Footer from "../shared-components/components/Footer/footer"
import MenuContainer from "../shared-components/components/Menu/menu"
import MenuTop from "../shared-components/components/MenuTop/menuTop"
import PermissionRoute from "../shared-components/components/PermissionRoute/permissionRoute"
import {DOMISERVE_CONTACT_URL} from "../shared-components/constants/links"
import {
    getCookiesChoices,
    getModalStep,
    isCookiesModalOpened,
    isCookiesUserAcceptanceExpired,
    isUserAcceptedCookies,
} from "../store/helpers/localStorage/cookiesHelper"
import {getDynamicConfigurationValue} from "../store/helpers/localStorage/dynamicConfigurationHelper"
import {getStyle} from "../store/helpers/localStorage/styleHelper"
import {getTexts} from "../store/helpers/localStorage/textsHelper"
import {
    getExpirationDate,
    getUserClientType,
    getUserFullname,
    getUserPermissions,
    getUserSocialReason,
    getUserToken,
    isUserConnected,
    isUserFromCollectivity,
    isUserFromPetitPro,
} from "../store/helpers/sessionStorage/userHelper"
import {isMenuResponsive} from "../store/helpers/temporaries/menuHelper"
import {getActualPage} from "../store/helpers/temporaries/pageHelper"
import {updateGlobalTexts} from "../styles/globalText"
import {AsyncBreadcrumbLayout, AsyncUnauthorizedScene} from "./asyncScenes"
import "./mainLayout.scss"
import {MatomoTrackPageView} from "./matomoTrackPageView"

const RedirectToHome = () => (
    <Redirect
        to={{
            pathname: PagesArchitecture[Page.HOME].route,
        }}
    />
)

const defaultRouteExternalSelectors = {
    getStyle,
    getTexts,
    getDynamicConfigurationValue,
    getExpirationDate,
    getUserClientType,
    getUserPermissions,
    getUserToken,
    updateGlobalTexts,
}

class MainLayout extends Component {
    static propsTypes = {
        isMenuResponsive: PropTypes.bool.isRequired,
        onContactClick: PropTypes.func.isRequired,
        onLegalClick: PropTypes.func.isRequired,
    }

    onDisconnectClick = () => {
        this.props.logoutUser()
        this.props.disconnectUser().then(() => this.props.history.push(PagesArchitecture[Page.LOGIN].route))
    }

    getBreadcrumbLayout = () => (
        <AsyncBreadcrumbLayout actualPage={this.props.actualPage} isUserConnected={this.props.isUserConnected} />
    )

    render() {
        const {
            actualPage,
            clearPreOrderSessionValues,
            isClientFromCollectivity,
            isClientFromPetitPro,
            isMenuResponsive,
            isUserConnected,
            onCookiesClick,
            onContactClick,
            onConnectClick,
            onHomeClick,
            onLegalClick,
            onProfileClick,
            setMenuResponsive,
            socialReason,
            username,
            userPermissions,
            userType,
        } = this.props

        return (
            <Router history={history}>
                <div
                    className={
                        "main-layout" +
                        (process.env.NODE_ENV === "production" && !process.env.REACT_APP_PROD_MODE
                            ? " main-layout-bordered"
                            : "")
                    }
                >
                    <MenuTop
                        isConnected={isUserConnected}
                        onConnectClick={onConnectClick}
                        onDisconnectClick={this.onDisconnectClick}
                        onProfileClick={userType === ClientType.PETIT_CLIENT ? onProfileClick : undefined}
                        socialReason={socialReason}
                        username={username}
                    />
                    <MatomoTrackPageView />
                    <div className="main-page">
                        <div className="menu-container">
                            <MenuContainer
                                areaType={AREA_TYPE}
                                isConnected={isUserConnected}
                                isMenuResponsive={isMenuResponsive}
                                menuContent={menuContent(
                                    actualPage,
                                    history,
                                    clearPreOrderSessionValues,
                                    isClientFromCollectivity,
                                    isClientFromPetitPro,
                                )}
                                onConnectClick={onConnectClick}
                                onDisconnectClick={this.onDisconnectClick}
                                onHomeClick={onHomeClick}
                                onProfileClick={onProfileClick}
                                setMenuResponsive={setMenuResponsive}
                                title="ESPACE FINANCEUR"
                                userPermissions={userPermissions}
                            />
                        </div>
                        <div className="main-content">
                            <Suspense fallback={<div />}>
                                <Switch>
                                    {/* Redirect / to Home */}
                                    <PermissionRoute
                                        component={RedirectToHome}
                                        exact
                                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                                        path="/"
                                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                                        {...defaultRouteExternalSelectors}
                                    />

                                    {/* Unauthorized route. */}
                                    <Route
                                        component={AsyncUnauthorizedScene}
                                        exact
                                        path={PagesArchitecture[Page.UNAUTHORIZED].route}
                                    />

                                    {/* Breadcrumb layout */}
                                    <Route component={this.getBreadcrumbLayout} path="" />
                                </Switch>
                            </Suspense>
                        </div>
                    </div>
                    <CookiesNotificationContainer
                        getCookiesChoices={getCookiesChoices}
                        getModalStep={getModalStep}
                        isCookiesModalOpened={isCookiesModalOpened}
                        isCookiesUserAcceptanceExpired={isCookiesUserAcceptanceExpired}
                        isUserAcceptedCookies={isUserAcceptedCookies}
                    />
                    <Footer
                        onContactClick={onContactClick}
                        onCookiesClick={onCookiesClick}
                        onLegalClick={onLegalClick}
                    />
                </div>
            </Router>
        )
    }
}

export default withRouter(
    connect(
        state => ({
            actualPage: getActualPage(state),
            isClientFromCollectivity: isUserFromCollectivity(state),
            isClientFromPetitPro: isUserFromPetitPro(state),
            isMenuResponsive: isMenuResponsive(state),
            isUserConnected: isUserConnected(state),
            socialReason: getUserSocialReason(state),
            userPermissions: getUserPermissions(state),
            userType: getUserClientType(state),
            username: getUserFullname(state),
        }),
        (dispatch, props) => ({
            // Display cookies modal
            onCookiesClick: () => dispatch(saveCookiesChoices({isModalOpened: true, step: "CONFIGURATION"})),

            // Go to the contact page.
            onContactClick: () => window.open(DOMISERVE_CONTACT_URL),

            // Go to the connection page.
            onConnectClick: () => props.history.push(PagesArchitecture[Page.LOGIN].route),

            // Disconnect the current user.
            disconnectUser: () => dispatch(disconnectUser()),
            logoutUser: () => dispatch(logoutUser()),

            // Go to the home page.
            onHomeClick: () => {
                props.history.push(PagesArchitecture[Page.HOME].route)
            },

            // Go to the legal mentions page.
            onLegalClick: () => props.history.push(PagesArchitecture[Page.LEGAL_NOTICE].route),

            // Go to the profile page.
            onProfileClick: () => {
                props.history.push(PagesArchitecture[Page.PROFILE].route)
            },

            setMenuResponsive: isResponsive => {
                dispatch(setResponsive(isResponsive))
            },

            clearPreOrderSessionValues: () => {
                dispatch(clearPreOrderInitValues())
                dispatch(clearPreOrderParticipationValues())
                dispatch(clearControlValues())
                dispatch(clearDeliveryValues())
                dispatch(clearCommunicationValues())
                dispatch(initialize("preOrderSessionInitForm"))
                // To update the breadcrumb layout when editing an other preorder session
                dispatch(changeActualPage(Page.PREORDER_SESSION_INIT))
            },
        }),
    )(MainLayout),
)
