import {combineReducers} from "redux"
import style, * as fromStyle from "../../shared-components/store/localStorage/style/styleReducer"
import texts, * as fromTexts from "../../shared-components/store/localStorage/texts/textsReducer"
import dynamicConfiguration, * as fromDynConf from "../../shared-components/store/localStorage/dynamicConfiguration/dynamicConfigurationReducer"
import contactInformation, * as fromContactInformation from "./contactInformation/contactInformationReducer"
import beneficiariesOrder, * as fromBeneficiariesOrder from "./beneficiariesOrder/beneficiariesOrderReducer"
import order, * as fromOrder from "./order/orderReducer"
import orderOverview, * as fromOrderOverview from "./orderOverview/orderOverviewReducer"
import cookies, * as fromCookies from "../../shared-components/store/localStorage/cookies/cookiesReducer"
import preOrderSessionInit, * as fromPreOrderSessionInit from "./preOrderSessionInit/preOrderSessionInitReducer"
import preOrderSessionParticipation, * as fromPreOrderSessionParticipation from "./preOrderSessionParticipation/preOrderSessionParticipationReducer"
import preOrderSessionDelivery, * as fromPreOrderSessionDelivery from "./preOrderSessionDelivery/preOrderSessionDeliveryReducer"
import preOrderSessionCommunication, * as fromPreOrderSessionCommunication from "./preOrderSessionCommunication/preOrderSessionCommunicationReducer"
import preOrderSessionControl, * as fromPreOrderSessionControl from "./preOrderSessionControl/preOrderSessionControlReducer"
import confirmation, * as fromConfirmation from "./confirmation/confirmationReducer"

/**
 * Part of the store to stock data which will be saved in local storage.
 */
const localStorage = combineReducers({
    beneficiariesOrder: beneficiariesOrder(),
    contactInformation,
    cookies,
    dynamicConfiguration,
    order,
    orderOverview,
    preOrderSessionCommunication: preOrderSessionCommunication(),
    preOrderSessionDelivery,
    preOrderSessionInit,
    preOrderSessionParticipation,
    preOrderSessionControl: preOrderSessionControl(),
    style,
    texts,
    confirmation,
})

export default localStorage

// DYNAMIC CONFIGURATION OPERATIONS
/**
 * Return the value associated with the key if it is found in the state,
 * undefined otherwise.
 */
export const getDynamicConfigurationValue = (state, key) => fromDynConf.getValue(state.dynamicConfiguration, key)

// CONTACT INFORMATION OPERATIONS
/**
 * Return the contact information values found in the state.
 */
export const getContactInformationValues = state => fromContactInformation.getValues(state.contactInformation)
/**
 * Return the SIRET value found in the state, undefined if it is not found.
 */
export const getContactInformationSiret = state => fromContactInformation.getSiret(state.contactInformation)

// BENEFICIARIES ORDER OPERATIONS
/**
 * Get IDs of all existing beneficiaries that are chosen to be in the order.
 */
export const getBeneficiariesOrderExistingChosenIds = state =>
    fromBeneficiariesOrder.getExistingChosenIds(state.beneficiariesOrder)
/**
 * Get IDs of all existing beneficiaries that aren't chosen to be in the order.
 */
export const getBeneficiariesOrderExistingNotChosenIds = state =>
    fromBeneficiariesOrder.getExistingNotChosenIds(state.beneficiariesOrder)
/**
 * Get IDs of all new beneficiaries that are chosen to be in the order.
 */
export const getBeneficiariesOrderNewChosenIds = state =>
    fromBeneficiariesOrder.getNewChosenIds(state.beneficiariesOrder)
/**
 * Get IDs of all new beneficiaries that aren't chosen to be in the order.
 */
export const getBeneficiariesOrderNewNotChosenIds = state =>
    fromBeneficiariesOrder.getNewNotChosenIds(state.beneficiariesOrder)
/**
 * Get a new beneficiary from its ID.
 * Returns undefined if the ID isn't found.
 */
export const getBeneficiariesOrderNewBeneficiaryFromId = (state, id) =>
    fromBeneficiariesOrder.getNewBeneficiaryFromId(state.beneficiariesOrder, id)
/**
 * Get an update for an existing beneficiary from its ID.
 * Returns undefined if the ID isn't found.
 */
export const getBeneficiariesOrderExistingBeneficiaryUpdateFromId = (state, id) =>
    fromBeneficiariesOrder.getExistingBeneficiaryUpdateFromId(state.beneficiariesOrder, id)
/**
 * Returns all existing beneficiaries which had an update.
 */
export const getBeneficiariesOrderAllExistingBeneficiaryUpdates = state =>
    fromBeneficiariesOrder.getAllExistingBeneficiaryUpdates(state.beneficiariesOrder)
/**
 * Get the details about the beneficiary ID in edition.
 */
export const getBeneficiariesOrderEditionIdDetail = state =>
    fromBeneficiariesOrder.getEditionIdDetail(state.beneficiariesOrder)

// ORDER OPERATIONS
/**
 * Get the preferential code.
 */
export const getOrderPreferentialCode = state => fromOrder.getPreferentialCode(state.order)
/**
 * Get the beneficiaries orders.
 */
export const getOrderBeneficiariesOrders = state => fromOrder.getBeneficiariesOrders(state.order)

// ORDER OVERVIEW OPERATIONS
/**
 * Return order overview state
 */
export const getOrderOverview = state => fromOrderOverview.getOrderOverview(state.orderOverview)

// CONFIRMATION OPERATIONS
/**
 * Return the confirmation values found in the state.
 */
export const getConfirmationValues = state => fromConfirmation.getValues(state.confirmation)

// STYLE OPERATIONS
/**
 * Get the style from the store.
 */
export const getStyle = (state, contractId) => fromStyle.getStyle(state.style, contractId)
/**
 * Is style customized ?
 */
export const isCustomizedStyle = (state, contractId) => fromStyle.isCustomized(state.style, contractId)

// TEXTS OPERATIONS
/**
 * Get the texts from the store.
 */
export const getTexts = (state, contractId) => fromTexts.getTexts(state.texts, contractId)
/**
 * Are texts customized ?
 */
export const areCustomizedTexts = (state, contractId) => fromTexts.areCustomized(state.texts, contractId)

// COOKIES OPERATIONS
/**
 * Get cookies
 */
export const getCookiesChoices = state => fromCookies.getCookiesChoices(state.cookies)

/**
 * Return pre order session init values
 */
export const getPreOrderSessionInit = state => fromPreOrderSessionInit.getPreOrderSessionInit(state.preOrderSessionInit)

/**
 * Return pre order session participation values
 */
export const getPreOrderSessionParticipation = state =>
    fromPreOrderSessionParticipation.getPreOrderSessionParticipation(state.preOrderSessionParticipation)

/**
 * Return pre order session delivery values
 */
export const getPreOrderSessionDelivery = state =>
    fromPreOrderSessionDelivery.getPreOrderSessionDelivery(state.preOrderSessionDelivery)

/**
 * Return pre order session communication values
 */
export const getPreOrderSessionCommunication = state =>
    fromPreOrderSessionCommunication.getPreOrderSessionCommunication(state.preOrderSessionCommunication)

/**
 * Return preorder session control fields
 */
export const getPreOrderSessionControlFields = state =>
    fromPreOrderSessionControl.getFields(state.preOrderSessionControl)

/**
 * Return the preorder session control file path
 */
export const getPreOrderSessionControlFilePath = state =>
    fromPreOrderSessionControl.getFilePath(state.preOrderSessionControl)

/**
 * Return preorder session control fields
 */
export const getPreOrderSessionControl = state =>
    fromPreOrderSessionControl.getPreOrderSessionControl(state.preOrderSessionControl)
