/**
 * Indicate that we want to save values of params for order history search
 * @type {string}
 */
export const SAVE_ORDER_HISTORY_SEARCH_PARAMS = "SAVE_ORDER_HISTORY_SEARCH_PARAMS";

/**
 * Indicate that we want to clear values of params for order history search
 * @type {string}
 */
export const CLEAR_ORDER_HISTORY_SEARCH_PARAMS = "CLEAR_ORDER_HISTORY_SEARCH_PARAMS";

/**
 * Indicate that we want to clear pagination for order history table
 * @type {string}
 */
export const CLEAR_ORDER_HISTORY_PAGINATION = "CLEAR_ORDER_HISTORY_PAGINATION";

/**
 * Indicate that we don't want to display details of an order
 */
export const CLEAR_DETAILS_ORDER = "CLEAR_DETAILS_ORDER";

/**
 * Indicate that we want to save values of order lines params for order lines search
 */
export const SAVE_ORDER_HISTORY_ORDER_LINES_SEARCH_PARAMS = "SAVE_ORDER_HISTORY_ORDER_LINES_SEARCH_PARAMS";
