import * as fromLocalStorage from "../../localStorage/localStorageReducer";
import * as fromRequests from "../../../shared-components/store/requests/requestsReducer";
import {RequestKeys, RequestInfo} from "../../../shared-components/constants/requests";

/**
 * Get the texts from the store.
 */
export const getTexts = (state, contractId) => fromLocalStorage.getTexts(state.localStorage, contractId);
/**
 * Is the application fetching texts ?
 */
export const isFetchingTexts = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.RETRIEVE_TEXTS].name, state.requests);
/**
 * Are texts customized ?
 */
export const isCustomizedTexts = (state, contractId) =>
    fromLocalStorage.areCustomizedTexts(state.localStorage, contractId);
