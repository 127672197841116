import {
    RETRIEVE_ORDER_DEPOSITS_REQUEST,
    RETRIEVE_ORDER_DEPOSITS_SUCCESS,
    RETRIEVE_ORDER_DEPOSITS_FAILURE,
} from "../../../shared-components/actions/entities/orderDeposits/orderDepositActionTypes";

const orderDepositHistory = (state = {}, action = {}) => {
    switch (action.type) {
        case RETRIEVE_ORDER_DEPOSITS_SUCCESS:
            return action.pagination;
        case RETRIEVE_ORDER_DEPOSITS_REQUEST:
        case RETRIEVE_ORDER_DEPOSITS_FAILURE:
            return {};
        default:
            return state;
    }
};

export default orderDepositHistory;

/**
 * Return pagination information
 */
export const getPagination = state => state;
