import {combineReducers} from "redux"
import {
    ADD_NEW_BENEFICIARY,
    CHOOSE_BENEFICIARIES_FOR_ORDER,
    CLEAR_EDIT_BENEFICIARY,
    DESELECT_ALL_CHOSEN,
    DESELECT_ALL_NOT_CHOSEN,
    EDIT_BENEFICIARY,
    EMPTY_BENEFICIAIRES_ORDER,
    REJECT_BENEFICIARIES_OF_ORDER,
    REMOVE_BENEFICIARY,
    SELECT_ALL_CHOSEN,
    SELECT_ALL_NOT_CHOSEN,
    TOGGLE_SELECTION_BENEFICIARY,
    UPDATE_BENEFICIARY,
} from "../../../actions/localStorage/beneficiariesOrder/beneficiariesOrderActionTypes"
import {omit} from "lodash"
import {
    EXISTING_BENEFICIARY,
    NEW_BENEFICIARY,
} from "../../../actions/localStorage/beneficiariesOrder/beneficiariesOrderActions"
import {GET_DUPLICATION_INFORMATION_SUCCESS} from "../../../shared-components/actions/temporaries/onlineOrder/onlineOrderActionTypes"
import {RETRIEVE_BENEFICIARIES_SUCCESS} from "../../../shared-components/actions/entities/beneficiaries/beneficiariesActionTypes"

/**
 * A reducer to store beneficiaries used in orders.
 * Only store new beneficiaries, and just IDs of existing ones.
 */
const beneficiariesOrder = () => {
    const ids = (state = [], action = {}) => {
        switch (action.type) {
            case ADD_NEW_BENEFICIARY:
                return [
                    ...state,
                    {
                        id: action.id,
                        type: NEW_BENEFICIARY,
                        chosen: true,
                        selected: false,
                    },
                ]
            case RETRIEVE_BENEFICIARIES_SUCCESS:
                return [
                    ...state.filter(val => val.type !== EXISTING_BENEFICIARY),
                    ...action.response.result.map(id => {
                        const val = state.filter(val => val.id === id)

                        if (val.length === 0) {
                            return {
                                id,
                                type: EXISTING_BENEFICIARY,
                                chosen: false,
                                selected: false,
                            }
                        } else {
                            return val[0]
                        }
                    }),
                ]
            case REMOVE_BENEFICIARY:
                return state.filter(val => val.id !== action.id)
            case TOGGLE_SELECTION_BENEFICIARY:
                return state.map(
                    val =>
                        val.id === action.id
                            ? {
                                  ...val,
                                  selected: !val.selected,
                              }
                            : val,
                )
            case SELECT_ALL_CHOSEN:
                return state.map(
                    val =>
                        val.chosen
                            ? {
                                  ...val,
                                  selected: true,
                              }
                            : val,
                )
            case DESELECT_ALL_CHOSEN:
                return state.map(
                    val =>
                        val.chosen
                            ? {
                                  ...val,
                                  selected: false,
                              }
                            : val,
                )
            case SELECT_ALL_NOT_CHOSEN:
                return state.map(
                    val =>
                        !val.chosen
                            ? {
                                  ...val,
                                  selected: true,
                              }
                            : val,
                )
            case DESELECT_ALL_NOT_CHOSEN:
                return state.map(
                    val =>
                        !val.chosen
                            ? {
                                  ...val,
                                  selected: false,
                              }
                            : val,
                )
            case CHOOSE_BENEFICIARIES_FOR_ORDER:
                return state.map(
                    val =>
                        action.ids.includes(val.id)
                            ? {
                                  ...val,
                                  chosen: true,
                              }
                            : val,
                )
            case REJECT_BENEFICIARIES_OF_ORDER:
                return state.map(
                    val =>
                        action.ids.includes(val.id)
                            ? {
                                  ...val,
                                  chosen: false,
                              }
                            : val,
                )
            case EMPTY_BENEFICIAIRES_ORDER:
                return state.filter(val => val.type !== NEW_BENEFICIARY).map(val => ({
                    ...val,
                    chosen: false,
                }))
            case GET_DUPLICATION_INFORMATION_SUCCESS:
                return action.ids
            default:
                return state
        }
    }

    const addedBeneficiaries = (state = {}, action = {}) => {
        switch (action.type) {
            case ADD_NEW_BENEFICIARY:
                return {
                    ...state,
                    [action.id]: {
                        ...omit(action, ["type"]),
                    },
                }
            case UPDATE_BENEFICIARY:
                return action.beneficiaryType === NEW_BENEFICIARY
                    ? {
                          ...state,
                          [action.id]: {
                              ...omit(action, ["type", "beneficiaryType"]),
                          },
                      }
                    : state
            case REMOVE_BENEFICIARY:
                return omit(state, [action.id])
            case EMPTY_BENEFICIAIRES_ORDER:
                return {}
            default:
                return state
        }
    }

    const editionID = (state = null, action = {}) => {
        switch (action.type) {
            case EDIT_BENEFICIARY:
                return action.id
            case CLEAR_EDIT_BENEFICIARY:
            case EMPTY_BENEFICIAIRES_ORDER:
                return null
            default:
                return state
        }
    }

    const existingBeneficiariesToUpdate = (state = {}, action = {}) => {
        switch (action.type) {
            case EMPTY_BENEFICIAIRES_ORDER:
                return {}
            case UPDATE_BENEFICIARY:
                return action.beneficiaryType === EXISTING_BENEFICIARY
                    ? {
                          ...state,
                          [action.id]: {
                              ...omit(action, ["type", "beneficiaryType"]),
                          },
                      }
                    : state
            default:
                return state
        }
    }

    return combineReducers({
        ids,
        addedBeneficiaries,
        editionID,
        existingBeneficiariesToUpdate,
    })
}

export default beneficiariesOrder

/**
 * Get IDs of all existing beneficiaries that are chosen to be in the order.
 */
export const getExistingChosenIds = state => state.ids.filter(val => val.type === EXISTING_BENEFICIARY && val.chosen)

/**
 * Get IDs of all existing beneficiaries that aren't chosen to be in the order.
 */
export const getExistingNotChosenIds = state =>
    state.ids.filter(val => val.type === EXISTING_BENEFICIARY && !val.chosen)

/**
 * Get IDs of all new beneficiaries that are chosen to be in the order.
 */
export const getNewChosenIds = state => state.ids.filter(val => val.type === NEW_BENEFICIARY && val.chosen)

/**
 * Get IDs of all new beneficiaries that aren't chosen to be in the order.
 */
export const getNewNotChosenIds = state => state.ids.filter(val => val.type === NEW_BENEFICIARY && !val.chosen)

/**
 * Get a new beneficiary from its ID.
 * Returns undefined if the ID isn't found.
 */
export const getNewBeneficiaryFromId = (state, id) => state.addedBeneficiaries[id]

/**
 * Get an update for an existing beneficiary from its ID.
 * Returns undefined if the ID isn't found.
 */
export const getExistingBeneficiaryUpdateFromId = (state, id) => state.existingBeneficiariesToUpdate[id]

/**
 * Returns all existing beneficiaries which had an update.
 */
export const getAllExistingBeneficiaryUpdates = state => state.existingBeneficiariesToUpdate

/**
 * Get the details about the beneficiary ID in edition.
 */
export const getEditionIdDetail = state =>
    state.editionID
        ? state.ids.filter(val => val.id === state.editionID).reduce((a, b) => {
              return b
          }, {})
        : null
