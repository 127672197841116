import {combineReducers} from "redux"
import {
    RETRIEVE_ORDERS_REQUEST,
    RETRIEVE_ORDERS_SUCCESS,
    RETRIEVE_ORDERS_FAILURE,
    RETRIEVE_ORDER_SUCCESS,
    RETRIEVE_ORDER_REQUEST,
    RETRIEVE_ORDER_FAILURE,
} from "../../../shared-components/actions/entities/orders/orderActionTypes"
import {
    CLEAR_DETAILS_ORDER,
    CLEAR_ORDER_HISTORY_PAGINATION,
    CLEAR_ORDER_HISTORY_SEARCH_PARAMS,
    SAVE_ORDER_HISTORY_SEARCH_PARAMS,
    SAVE_ORDER_HISTORY_ORDER_LINES_SEARCH_PARAMS,
} from "../../../actions/temporaries/orderHistory/orderHistorySceneActionTypes"
import {
    RETRIEVE_ORDER_LINES_REQUEST,
    RETRIEVE_ORDER_LINES_SUCCESS,
    RETRIEVE_ORDER_LINES_FAILURE,
} from "../../../shared-components/actions/entities/orderLines/orderLinesActionTypes"

export const orderHistory = () => {
    const pagination = (state = {}, action = {}) => {
        switch (action.type) {
            case RETRIEVE_ORDERS_SUCCESS:
                return action.pagination ? action.pagination : {}

            case RETRIEVE_ORDERS_REQUEST:
            case RETRIEVE_ORDERS_FAILURE:
            case CLEAR_ORDER_HISTORY_PAGINATION:
                return {}
            default:
                return state
        }
    }

    const viewDetails = (state = {}, action = {}) => {
        switch (action.type) {
            case RETRIEVE_ORDER_SUCCESS:
                return action.response
            case RETRIEVE_ORDER_REQUEST:
            case RETRIEVE_ORDER_FAILURE:
            case CLEAR_DETAILS_ORDER:
                return {}
            default:
                return state
        }
    }

    const searchParams = (state = {}, action = {}) => {
        switch (action.type) {
            case SAVE_ORDER_HISTORY_SEARCH_PARAMS:
                return action.formValues || {}

            case CLEAR_ORDER_HISTORY_SEARCH_PARAMS:
                return {}

            default:
                return state
        }
    }

    const orderLinesPagination = (state = {}, action = {}) => {
        switch (action.type) {
            case RETRIEVE_ORDER_LINES_SUCCESS:
                return action.pagination ? action.pagination : {}

            case RETRIEVE_ORDER_LINES_REQUEST:
            case RETRIEVE_ORDER_LINES_FAILURE:
            case CLEAR_ORDER_HISTORY_PAGINATION:
                return {}
            default:
                return state
        }
    }

    const orderLinesSearchParams = (state = {}, action = {}) => {
        switch (action.type) {
            case SAVE_ORDER_HISTORY_ORDER_LINES_SEARCH_PARAMS:
                return action.formValues

            case CLEAR_ORDER_HISTORY_SEARCH_PARAMS:
                return {}

            default:
                return state
        }
    }

    return combineReducers({
        pagination,
        viewDetails,
        searchParams,
        orderLinesPagination,
        orderLinesSearchParams,
    })
}

/**
 * Return pagination information
 */
export const getPagination = state => state.pagination

/**
 * Return view details of order
 */
export const getViewDetails = state => state.viewDetails

/**
 * Return values of search params
 */
export const getSearchParams = state => state.searchParams

/**
 * Return order lines pagination information
 */
export const getOrderLinesPagination = state => state.orderLinesPagination
/**
 * Return values of order lines search params
 */
export const getOrderLinesSearchParams = state => state.orderLinesSearchParams
