import {
    CLEAR_DETAILS_BENEFICIARY,
    RETRIEVE_BENEFICIARIES_FAILURE,
    RETRIEVE_BENEFICIARIES_REQUEST,
    RETRIEVE_BENEFICIARIES_SUCCESS,
    VIEW_DETAILS_BENEFICIARY,
} from "../../../shared-components/actions/entities/beneficiaries/beneficiariesActionTypes";
import {combineReducers} from "redux";
import {
    CLEAR_BENEFICIARIES_PAGINATION,
    CLEAR_BENEFICIARIES_SEARCH_PARAMS,
    SAVE_BENEFICIARIES_SEARCH_PARAMS,
} from "../../../actions/temporaries/beneficiaries/beneficiariesSceneActionTypes";

/**
 * Save pagination and selected details beneficiary into the temporaries branch into the store.
 */
export const beneficiaries = () => {
    const pagination = (state = {}, action = {}) => {
        switch (action.type) {
            case RETRIEVE_BENEFICIARIES_SUCCESS:
                return action.pagination ? action.pagination : {};

            case RETRIEVE_BENEFICIARIES_REQUEST:
            case RETRIEVE_BENEFICIARIES_FAILURE:
            case CLEAR_BENEFICIARIES_PAGINATION:
                return {};

            default:
                return state;
        }
    };

    const viewDetails = (state = null, action = {}) => {
        switch (action.type) {
            case VIEW_DETAILS_BENEFICIARY:
                return action.beneficiaryId;

            case CLEAR_DETAILS_BENEFICIARY:
                return null;

            default:
                return state;
        }
    };

    const searchParams = (state = {}, action = {}) => {
        switch (action.type) {
            case SAVE_BENEFICIARIES_SEARCH_PARAMS:
                return action.formValues;

            case CLEAR_BENEFICIARIES_SEARCH_PARAMS:
                return {};

            default:
                return state;
        }
    };

    return combineReducers({
        pagination,
        viewDetails,
        searchParams,
    });
};

/**
 * Return pagination information
 */
export const getPagination = state => state.pagination;

/**
 * Return view details of beneficiary
 */
export const getViewDetails = state => state.viewDetails;

/**
 * Return values of search params
 */
export const getSearchParams = state => state.searchParams;
