import * as fromTemporaries from "../../temporaries/temporariesReducer";

/**
 * Return the actual page.
 */
export const getActualPage = state => fromTemporaries.getActualPage(state.temporaries);

/**
 * Return the previous page.
 */
export const getPreviousPage = state => fromTemporaries.getPreviousPage(state.temporaries);
