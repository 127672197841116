import {get} from "axios";
import {getErrorMessage} from "../../../shared-components/services/api";
import {
    ADD_NEW_BENEFICIARY,
    CHOOSE_BENEFICIARIES_FOR_ORDER,
    CLEAR_EDIT_BENEFICIARY,
    DESELECT_ALL_CHOSEN,
    DESELECT_ALL_NOT_CHOSEN,
    EDIT_BENEFICIARY,
    EMPTY_BENEFICIAIRES_ORDER,
    REJECT_BENEFICIARIES_OF_ORDER,
    REMOVE_BENEFICIARY,
    SELECT_ALL_CHOSEN,
    SELECT_ALL_NOT_CHOSEN,
    TOGGLE_SELECTION_BENEFICIARY,
    UPDATE_BENEFICIARY,
} from "./beneficiariesOrderActionTypes";
import {
    GET_DUPLICATION_INFORMATION_REQUEST,
    GET_DUPLICATION_INFORMATION_SUCCESS,
    GET_DUPLICATION_INFORMATION_FAILURE,
} from "../../../shared-components/actions/temporaries/onlineOrder/onlineOrderActionTypes";
import {GET_DUPLICATION_INFORMATION_ENDPOINT} from "../../../shared-components/constants/endpoints";

/**
 * A new beneficiary.
 */
export const NEW_BENEFICIARY = "NEW_BENEFICIARY";
/**
 * An existing beneficiary.
 */
export const EXISTING_BENEFICIARY = "EXISTING_BENEFICIARY";

/**
 * Action to add a new beneficiary.
 * @param id the id
 * @param civility the responsible civility
 * @param name the responsible name
 * @param firstName the responsible first name
 * @param mail the responsible mail address
 * @param phoneNumber the responsible phone number
 * @param birthDate the birth date
 * @param address the beneficiary address
 * @param addressComplement a complementary field for the address
 * @param locality a locality for the address
 * @param postalCodeTown the postal code and the town
 */
export const addNewBeneficiary = (
    id,
    civility,
    name,
    firstName,
    mail,
    phoneNumber,
    birthDate,
    address,
    addressComplement,
    locality,
    postalCodeTown,
) => {
    return {
        type: ADD_NEW_BENEFICIARY,
        id,
        civility,
        name,
        firstName,
        mail,
        phoneNumber,
        birthDate,
        address,
        addressComplement,
        locality,
        postalCodeTown,
    };
};

/**
 * Remove a beneficiary.
 */
export const removeBeneficiary = id => {
    return {
        type: REMOVE_BENEFICIARY,
        id,
    };
};

/**
 * Edit a beneficiary.
 */
export const editBeneficiary = id => {
    return {
        type: EDIT_BENEFICIARY,
        id,
    };
};

/**
 * Action to update a new beneficiary.
 * @param id the id
 * @param beneficiaryType the type, between NEW_BENEFICIARY and EXISTING_BENEFICIARY
 * @param civility the responsible civility
 * @param name the responsible name
 * @param firstName the responsible first name
 * @param mail the responsible mail address
 * @param phoneNumber the responsible phone number
 * @param birthDate the birth date
 * @param address the beneficiary address
 * @param addressComplement a complementary field for the address
 * @param locality a locality for the address
 * @param postalCodeTown the postal code and the town
 */
export const updateBeneficiary = (
    id,
    beneficiaryType,
    civility,
    name,
    firstName,
    mail,
    phoneNumber,
    birthDate,
    address,
    addressComplement,
    locality,
    postalCodeTown,
) => {
    return {
        type: UPDATE_BENEFICIARY,
        id,
        beneficiaryType,
        civility,
        name,
        firstName,
        mail,
        phoneNumber,
        birthDate,
        address,
        addressComplement,
        locality,
        postalCodeTown,
    };
};

/**
 * Clear the beneficiary in edition.
 */
export const clearBeneficiaryInEdtion = () => {
    return {
        type: CLEAR_EDIT_BENEFICIARY,
    };
};

/**
 * Toggle selection of a beneficiary.
 */
export const toggleSelectionBeneficiary = id => {
    return {
        type: TOGGLE_SELECTION_BENEFICIARY,
        id,
    };
};

/**
 * Select all chosen beneficiaries.
 */
export const selectAllChosen = () => {
    return {
        type: SELECT_ALL_CHOSEN,
    };
};

/**
 * Deselect all chosen beneficiaries.
 */
export const deselectAllChosen = () => {
    return {
        type: DESELECT_ALL_CHOSEN,
    };
};

/**
 * Select all not chosen.
 */
export const selectAllNotChosen = () => {
    return {
        type: SELECT_ALL_NOT_CHOSEN,
    };
};

/**
 * Deselect all not chosen.
 */
export const deselectAllNotChosen = () => {
    return {
        type: DESELECT_ALL_NOT_CHOSEN,
    };
};

/**
 * Choose a beneficiary for a order.
 */
export const chooseBeneficiariesForOrder = ids => {
    return {
        type: CHOOSE_BENEFICIARIES_FOR_ORDER,
        ids,
    };
};

/**
 * Reject a beneficiary of a order.
 */
export const rejectBeneficiariesOfOrder = ids => {
    return {
        type: REJECT_BENEFICIARIES_OF_ORDER,
        ids,
    };
};

/**
 * Empty the beneficiary order.
 */
export const emptyBeneficiariesOrder = () => {
    return {
        type: EMPTY_BENEFICIAIRES_ORDER,
    };
};

/**
 * Get duplication information
 * @param  {[type]} order1Id an order id
 * @param  {[type]} order2Id an order id
 */
export const getDuplicationInformation = (order1Id, order2Id) => dispatch => {
    dispatch({
        type: GET_DUPLICATION_INFORMATION_REQUEST,
    });

    const params = {
        idCommande1: order1Id,
        idCommande2: order2Id,
    };

    return get(GET_DUPLICATION_INFORMATION_ENDPOINT, {params}).then(
        response => {
            const beneficiariesOrders = {};
            const ids = [];

            response.data.commandesBeneficiaires.forEach(beneficiaryOrder => {
                const beneficiaryId = beneficiaryOrder.beneficiaire.id;
                ids.push({
                    id: beneficiaryId,
                    type: EXISTING_BENEFICIARY,
                    chosen: true,
                    selected: false,
                });

                beneficiariesOrders[beneficiaryId] = {
                    account: !!beneficiaryOrder.valeurCompte,
                    amount: beneficiaryOrder.valeurCompte ? beneficiaryOrder.valeurCompte.montant : 0,
                    amountFundedPart: beneficiaryOrder.valeurCompte ? beneficiaryOrder.valeurCompte.partFinancee : 0,
                    title: !!beneficiaryOrder.valeurTitre,
                    checkbook: beneficiaryOrder.valeurTitre ? beneficiaryOrder.valeurTitre.chequier : undefined,
                    cesuNumber: beneficiaryOrder.valeurTitre ? beneficiaryOrder.valeurTitre.nombreCESU : 0,
                    cesuValue: beneficiaryOrder.valeurTitre ? beneficiaryOrder.valeurTitre.valeurCESU : 0,
                    titleFundedPart: beneficiaryOrder.valeurTitre ? beneficiaryOrder.valeurTitre.partFinancee : 0,
                };
            });

            dispatch({
                type: GET_DUPLICATION_INFORMATION_SUCCESS,
                beneficiariesOrders,
                ids,
            });

            return Promise.resolve();
        },
        error => {
            dispatch({
                type: GET_DUPLICATION_INFORMATION_FAILURE,
                message: getErrorMessage(error),
            });

            return Promise.reject();
        },
    );
};
