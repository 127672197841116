import {Page} from "./pages"

/**
 * Architecture of the pages of the application.
 * Structure :
 * - breadcrumbText : the text print in a breadcrumb
 * - route : the route to go to the page
 * - routeWithParamDesc : a second route, with param
 * - routeWithParam : a function take all params for the routeWithParamDesc and building a route
 * - parent : the parent page
 * - parentDisconnect : the parent page if no user is connected
 */
export const PagesArchitecture = {
    [Page.BENEFICIARIES]: {
        breadcrumbText: "Bénéficiaires",
        route: "/beneficiaires",
        parent: Page.HOME,
        permissions: ["BENEF_LIEN_SESSION", "SUIVI_STAT"],
        userConnected: true,
    },
    [Page.BENEFICIARY_STATS]: {
        breadcrumbText: "Statistiques",
        route: "/beneficiaires/statistiques",
        parent: Page.BENEFICIARIES,
        permissions: ["SUIVI_STAT"],
        userConnected: true,
    },
    [Page.BYPASS_LOGIN]: {
        route: "/bypass-login",
        userConnected: false,
    },
    [Page.INTERLOCUTOR_ADMINISTRATION]: {
        breadcrumbText: "Administration des gestionnaires",
        parent: Page.HOME,
        permissions: ["ADMINISTRATION_GESTIONNAIRES"],
        route: "/gestionnaires/administration",
        userConnected: true,
    },
    [Page.INTERLOCUTOR_ACCOUNT_ACCESS]: {
        breadcrumbText: "Accès compte",
        parent: Page.HOME,
        permissions: ["ACCES_COMPTE_GESTIONNAIRE", "ACCES_COMPTE_INTERLOCUTEUR_CONSULTATION"],
        route: "/gestionnaires/acces-comptes",
        userConnected: true,
    },
    [Page.GENERAL_DATA]: {
        breadcrumbText: "Données générales",
        parent: Page.HOME,
        permissions: ["DONNEES_GENE"],
        route: "/statistiques/generales",
        userConnected: true,
    },
    [Page.HOME]: {
        breadcrumbText: "Accueil",
        route: "/accueil",
        userConnected: true,
    },
    [Page.INTERVENANT_SEARCH]: {
        breadcrumbText: "Suivi par intervenant",
        parent: Page.HOME,
        route: "/statistiques/suivi-intervenant",
        userConnected: true,
    },
    [Page.INTERVENANT_STATS]: {
        breadcrumbText: "Statistiques",
        route: "/statistiques/suivi-intervenant/intervenant",
        parent: Page.INTERVENANT_SEARCH,
        permissions: ["SUIVI_INTER"],
        userConnected: true,
    },
    [Page.LEGAL_NOTICE]: {
        breadcrumbText: "Mentions Légales",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        route: "/mentions-legales",
    },
    [Page.LOGIN]: {
        breadcrumbText: "Connexion",
        route: "/login",
        routeWithParamDesc: "/:contractId/login",
        routeWithParam: contractId => "/" + contractId + "/login",
    },
    [Page.ASK_FOR_HELP]: {
        breadcrumbText: "Aide",
        route: "https://www.domiserve.com/355/guides-d-utilisation.htm#c2316",
    },
    [Page.ASK_FOR_HELP_CONNECTED]: {
        breadcrumbText: "Aide",
        route: "https://www.domiserve.com/355/guides-d-utilisation.htm#c2316",
        userConnected: true,
    },
    [Page.MANAGEMENT_REQUEST]: {
        breadcrumbText: "Demande de gestion",
        parent: Page.MANAGEMENT_REQUESTS_LIST,
        route: "/demande-gestion",
        permissions: ["VISU_DEMANDE_GESTION"],
        userConnected: true,
    },
    [Page.MANAGEMENT_REQUESTS_LIST]: {
        breadcrumbText: "Liste des demandes de gestion",
        parent: Page.HOME,
        route: "/demandes-gestion",
        permissions: ["VISU_DEMANDE_GESTION"],
        userConnected: true,
    },
    [Page.ORDER_BENEFICIARIES]: {
        breadcrumbText: "Commander en ligne",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        permissions: ["SAISIE_CMD_EN_LIGNE"],
        route: "/commande/beneficiaires",
        userConnected: true,
    },
    [Page.ORDER_CONFIRMATION]: {
        breadcrumbText: "Commander en ligne",
        route: "/commande/confirmation",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        permissions: ["SAISIE_CMD_EN_LIGNE"],
    },
    [Page.ORDER_CONTACT_INFORMATION]: {
        breadcrumbText: "Commander en ligne",
        route: "/commande/coordonnees",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        permissions: ["SAISIE_CMD_EN_LIGNE"],
    },
    [Page.ORDER_DEPOSIT]: {
        breadcrumbText: "Déposer fichier Excel",
        route: "/commande/depot",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD"],
        userConnected: true,
    },
    [Page.ORDER_DEPOSIT_HISTORY]: {
        breadcrumbText: "Historique des dépôts",
        route: "/commande/depot/historique",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD"],
        userConnected: true,
    },
    [Page.ORDER_HISTORY]: {
        breadcrumbText: "Historique des commandes",
        parent: Page.HOME,
        permissions: ["SUIVI_CMD", "VISU_DEMANDE_GESTION"],
        route: "/commande/historique",
        userConnected: true,
    },
    [Page.ORDER_ORDER]: {
        breadcrumbText: "Commander en ligne",
        route: "/commande/choix",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        permissions: ["SAISIE_CMD_EN_LIGNE"],
    },
    [Page.ORDER_OVERVIEW]: {
        breadcrumbText: "Commander en ligne",
        route: "/commande/recapitulatif",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        permissions: ["SAISIE_CMD_EN_LIGNE"],
    },
    [Page.ORDER_PAYMENT]: {
        breadcrumbText: "Commander en ligne",
        route: "/commande/paiement",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        permissions: ["SAISIE_CMD_EN_LIGNE"],
    },
    [Page.ORDER_SIRET_INFORMATION]: {
        breadcrumbText: "Commander en ligne",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        permissions: ["SAISIE_CMD_EN_LIGNE"],
        route: "/commande/siret",
    },
    [Page.PREORDER_SESSION_INIT]: {
        breadcrumbText: "Créer une nouvelle session de précommande",
        route: "/session-precommande/initialisation",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_PARTICIPATION]: {
        breadcrumbText: "Créer une nouvelle session de précommande",
        route: "/session-precommande/participation",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_CONTROL]: {
        breadcrumbText: "Créer une nouvelle session de précommande",
        route: "/session-precommande/controle",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_DELIVERY]: {
        breadcrumbText: "Créer une nouvelle session de précommande",
        route: "/session-precommande/livraison",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_COMMUNICATION]: {
        breadcrumbText: "Créer une nouvelle session de précommande",
        route: "/session-precommande/communication",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_CONFIRMATION]: {
        breadcrumbText: "Créer une nouvelle session de précommande",
        route: "/session-precommande/confirmation",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_MANAGEMENT]: {
        breadcrumbText: "Gérer mes sessions",
        route: "/session-precommande/gestion",
        parent: Page.HOME,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_LINES_MANAGEMENT]: {
        breadcrumbText: "Précommandes",
        routeWithParamDesc: "/session-precommande/:sessionId/precommandes",
        routeWithParam: sessionId => "/session-precommande/" + sessionId + "/precommandes",
        parent: Page.PREORDER_SESSION_MANAGEMENT,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_SUM_UP]: {
        breadcrumbText: "Bilan",
        routeWithParamDesc: "/session-precommande/:sessionId/bilan",
        routeWithParam: sessionId => "/session-precommande/" + sessionId + "/bilan",
        parent: Page.PREORDER_SESSION_MANAGEMENT,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_EDIT_INIT]: {
        breadcrumbText: "Modifier ma session de précommande",
        route: "/session-precommande/initialisation",
        parent: Page.PREORDER_SESSION_MANAGEMENT,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_EDIT_PARTICIPATION]: {
        breadcrumbText: "Modifier ma session de précommande",
        route: "/session-precommande/participation",
        parent: Page.PREORDER_SESSION_MANAGEMENT,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_EDIT_CONTROL]: {
        breadcrumbText: "Modifier ma session de précommande",
        route: "/session-precommande/controle",
        parent: Page.PREORDER_SESSION_MANAGEMENT,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_EDIT_DELIVERY]: {
        breadcrumbText: "Modifier ma session de précommande",
        route: "/session-precommande/livraison",
        parent: Page.PREORDER_SESSION_MANAGEMENT,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PREORDER_SESSION_EDIT_COMMUNICATION]: {
        breadcrumbText: "Modifier ma session de précommande",
        route: "/session-precommande/communication",
        parent: Page.PREORDER_SESSION_MANAGEMENT,
        permissions: ["SAISIE_CMD_PRECMD"],
        userConnected: true,
    },
    [Page.PROFILE]: {
        breadcrumbText: "Profil",
        route: "/profil",
        parent: Page.HOME,
        userConnected: true,
    },
    [Page.RECIPIENTS]: {
        breadcrumbText: "Allocataires",
        route: "/allocataires",
        parent: Page.HOME,
        permissions: ["BENEF_LIEN_SESSION", "SUIVI_STAT"],
        userConnected: true,
    },
    [Page.RECIPIENT_STATS]: {
        breadcrumbText: "Statistiques",
        route: "/allocataires/statistiques",
        parent: Page.RECIPIENTS,
        permissions: ["SUIVI_STAT"],
        userConnected: true,
    },
    [Page.RECOVER_LOGIN_PASSWORD]: {
        breadcrumbText: "Retrouver son mot de passe",
        route: "/recoverLoginPassword",
    },
    [Page.REPORTS]: {
        breadcrumbText: "Mes rapports",
        route: "/rapports",
        parent: Page.HOME,
        permissions: ["VISU_ARBO_RAPPORT"],
        userConnected: true,
    },
    [Page.REQUEST_MANAGEMENT]: {
        breadcrumbText: "Demandes de gestion",
        parent: Page.HOME,
        permissions: ["VISU_DEMANDE_GESTION"],
        userConnected: true,
    },
    [Page.RESET_PASSWORD]: {
        breadcrumbText: "Réinitialiser son mot de passe",
        routeWithParamDesc: "/resetPassword/:token",
        routeWithParam: token => "/resetPassword/" + token,
    },
    [Page.STYLEGUIDE]: {
        breadcrumbText: "Styleguide",
        route: "/styleguide",
    },
    [Page.TECHNICAL_ERROR]: {
        route: "/erreur-technique",
    },
    [Page.UNAUTHORIZED]: {
        breadcrumbText: "Accès interdit",
        route: "/unauthorized",
        parent: Page.HOME,
    },
}
