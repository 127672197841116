import {CLEAR_INIT_VALUES, SAVE_INIT_VALUES} from "./preOrderSessionInitActionTypes";

/**
 * Action to save pre order init values
 * @param id preorder session id
 * @param label pre order session label
 * @param beginDate begin date of the preOrder session
 * @param endDate end date of the preOrder session
 * @param permanent if the preOrder session is permanent
 * @param accountProduct preOrder account product
 * @param checkbookProduct preOrder checkbook product
 * @param customLabelAccountProduct custom label for account product
 * @param customLabelCheckbookProduct custom label for checkbook product
 * @param facialValue facialValue restriction for checkbook
 * @param checkbookCheckNumberRestrictions restriction on number of check in checkbook
 * @param serviceType accountProduct usage restricted on a serviceType
 */
export const savePreOrderInitValues = (
    id,
    label,
    beginDate,
    endDate,
    permanent,
    accountProduct,
    checkbookProduct,
    customLabelAccountProduct,
    customLabelCheckbookProduct,
    facialValue,
    checkbookCheckNumberRestrictions,
    serviceType,
) => {
    return {
        type: SAVE_INIT_VALUES,
        id,
        label,
        beginDate,
        endDate,
        permanent,
        accountProduct,
        checkbookProduct,
        customLabelAccountProduct,
        customLabelCheckbookProduct,
        facialValue,
        checkbookCheckNumberRestrictions,
        serviceType,
    };
};

/**
 * Indicate that we want to clear preOrderInit values
 */
export const clearPreOrderInitValues = () => ({
    type: CLEAR_INIT_VALUES,
});
