import {
    CLEAR_COMMUNICATION_VALUES,
    UPDATE_CONFIRMATION_MAIL_CONTENT,
    UPDATE_INTRODUCTION_MAIL_CONTENT,
    UPDATE_OPENING_MAIL_CONTENT,
    UPDATE_CLOSING_MAIL_CONTENT,
    UPDATE_DAYS_BEFORE_CLOSING_MAIL
} from "./preOrderSessionCommunicationActionTypes";

export const updateConfirmationMailContent = content => updateMailContent(UPDATE_CONFIRMATION_MAIL_CONTENT, content);
export const updateIntroductionMailContent = content => updateMailContent(UPDATE_INTRODUCTION_MAIL_CONTENT, content);
export const updateOpeningMailContent = content => updateMailContent(UPDATE_OPENING_MAIL_CONTENT, content);
export const updateClosingMailContent = content => updateMailContent(UPDATE_CLOSING_MAIL_CONTENT, content);

const updateMailContent = (actionType, content) => ({
    type: actionType,
    content
});

export const updateDaysBeforeClosingMail = days => ({ type: UPDATE_DAYS_BEFORE_CLOSING_MAIL, days });

/**
 * Clear the state of preOrderSessionCommunication
 */
export const clearCommunicationValues = () => ({
    type: CLEAR_COMMUNICATION_VALUES
});
