/**
 * Indicate that we want to save the ORDER_OVERVIEW.
 * @type {string}
 */
export const SAVE_ORDER_OVERVIEW = "SAVE_ORDER_OVERVIEW"

/**
 * Indicate that we want to clear all ORDER_OVERVIEW.
 * @type {string}
 */
export const CLEAR_ORDER_OVERVIEW = "CLEAR_ORDER_OVERVIEW"
