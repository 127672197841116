import * as fromTemporaries from "../../temporaries/temporariesReducer";

/**
 * Tell if we should display a success message after resetting password
 */
export const getResetPasswordDisplaySuccessMessage = state =>
    fromTemporaries.getResetPasswordDisplaySuccessMessage(state.temporaries);

/**
 * Return the password score from the store
 */
export const getResetPasswordScore = state => fromTemporaries.getPasswordScore(state.temporaries);
