import * as fromLocalStorage from "../../localStorage/localStorageReducer";
import * as fromRequests from "../../../shared-components/store/requests/requestsReducer";
import {RequestKeys, RequestInfo} from "../../../shared-components/constants/requests";

/**
 * Get the style from the store.
 */
export const getStyle = (state, contractId) => fromLocalStorage.getStyle(state.localStorage, contractId);
/**
 * Is the application fetching style ?
 */
export const isFetchingStyle = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.RETRIEVE_STYLE].name, state.requests);
/**
 * Is style customized ?
 */
export const areCustomizedStyle = (state, contractId) =>
    fromLocalStorage.isCustomizedStyle(state.localStorage, contractId);
