/**
 * Indicate that we want to save values of params for management requests search.
 */
export const SAVE_MANAGEMENT_REQUESTS_SEARCH_PARAMS = "SAVE_MANAGEMENT_REQUESTS_SEARCH_PARAMS"

/**
 * Indicate that we want to clear values of params for management requests search.
 */
export const CLEAR_MANAGEMENT_REQUESTS_SEARCH_PARAMS = "CLEAR_MANAGEMENT_REQUESTS_SEARCH_PARAMS"

/**
 * Indicate that we want to clear values of pagination for management requests table.
 */
export const CLEAR_MANAGEMENT_REQUESTS_PAGINATION = "CLEAR_MANAGEMENT_REQUESTS_PAGINATION"
