import React from "react"
import {PagesArchitecture} from "../constants/pagesArchitecture"
import {Page} from "../constants/pages"
import {AREA_TYPE} from "../constants/areaType"
import {
    getResetPasswordScore,
    getResetPasswordDisplaySuccessMessage,
} from "../store/helpers/temporaries/resetPasswordHelper"
import {isFetchingChangePassword, getChangePasswordErrorMessage} from "../store/helpers/changePasswordHelper"
import history from "../history"
import {FINANCEUR_AREA_URL} from "../shared-components/constants/links"
import {RequestInfo} from "../shared-components/constants/requests"
import {CommonRequestKeys} from "../shared-components/common/constants/requests"

const BreadcrumbLayout = React.lazy(() => import("./breadcrumbLayout").catch(() => window.location.reload()))

export const AsyncBreadcrumbLayout = props => (
    <BreadcrumbLayout isUserConnected={props.isUserConnected} pageName={props.actualPage} />
)

const BeneficiariesScene = React.lazy(() =>
    import("../scenes/Beneficiaries/beneficiariesContainer").catch(() => window.location.reload()),
)

export const AsyncBeneficiariesScene = () => <BeneficiariesScene />

const BeneficiaryStatsScene = React.lazy(() =>
    import("../scenes/BeneficiaryStats/beneficiaryStatsContainer").catch(() => window.location.reload()),
)

export const AsyncBeneficiaryStatsScene = () => <BeneficiaryStatsScene />

const GeneralDataScene = React.lazy(() =>
    import("../scenes/GeneralData/generalDataContainer").catch(() => window.location.reload()),
)

export const AsyncGeneralDataScene = () => <GeneralDataScene />

const InterlocutorAdministrationScene = React.lazy(() =>
    import("../scenes/InterlocutorAdministration/interlocutorAdministrationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncInterlocutorAdministrationScene = () => <InterlocutorAdministrationScene />

const InterlocutorAccountAccessScene = React.lazy(() =>
    import("../scenes/InterlocutorAccountAccess/interlocutorAccountAccessContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncInterlocutorAccountAccessScene = () => <InterlocutorAccountAccessScene />

const IntervenantSearchScene = React.lazy(() =>
    import("../scenes/IntervenantSearch/intervenantSearchContainer").catch(() => window.location.reload()),
)

export const AsyncIntervenantSearchScene = () => <IntervenantSearchScene />

const IntervenantStatsScene = React.lazy(() =>
    import("../scenes/IntervenantStats/intervenantStatsContainer").catch(() => window.location.reload()),
)

export const AsyncIntervenantStatsScene = () => <IntervenantStatsScene />

const LegalNoticeScene = React.lazy(() =>
    import("../shared-components/scenes/LegalNotice/legalNoticeContainer").catch(() => window.location.reload()),
)

export const AsyncLegalNoticeScene = () => (
    <LegalNoticeScene
        areaType={AREA_TYPE}
        legalNoticePage={Page.LEGAL_NOTICE}
        urlDomiserve={FINANCEUR_AREA_URL}
        withCGS={false}
    />
)

const OrderConfirmationScene = React.lazy(() =>
    import("../scenes/OrderConfirmation/orderConfirmationContainer").catch(() => window.location.reload()),
)

export const AsyncOrderConfirmationScene = () => <OrderConfirmationScene />

const OrderDepositHistoryScene = React.lazy(() =>
    import("../scenes/OrderDepositHistory/orderDepositHistoryContainer").catch(() => window.location.reload()),
)

export const AsyncOrderDepositHistoryScene = () => <OrderDepositHistoryScene />

const OrderDepositScene = React.lazy(() =>
    import("../scenes/OrderDeposit/orderDepositContainer").catch(() => window.location.reload()),
)

export const AsyncOrderDepositScene = () => <OrderDepositScene />

const OrderHistoryScene = React.lazy(() =>
    import("../scenes/OrderHistory/orderHistoryContainer").catch(() => window.location.reload()),
)

export const AsyncOrderHistoryScene = () => <OrderHistoryScene />

const ManagementRequestScene = React.lazy(() =>
    import("../scenes/ManagementRequest/managementRequestContainer").catch(() => window.location.reload()),
)

export const AsyncManagementRequestScene = () => <ManagementRequestScene />

const ManagementRequestsListScene = React.lazy(() =>
    import("../scenes/ManagementRequestsList/managementRequestsListContainer").catch(() => window.location.reload()),
)

export const AsyncManagementRequestsListScene = () => <ManagementRequestsListScene />

const PreOrderSessionManagementScene = React.lazy(() =>
    import("../scenes/PreOrderSessionManagement/preOrderSessionManagementContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncPreOrderSessionManagementScene = () => <PreOrderSessionManagementScene />

const PreOrderSessionSumUpScene = React.lazy(() =>
    import("../scenes/PreOrderSessionSumUp/preOrderSessionSumUpContainer").catch(() => window.location.reload()),
)

export const AsyncPreOrderSessionSumUpScene = () => <PreOrderSessionSumUpScene />

const PreOrderSessionLinesManagementScene = React.lazy(() =>
    import("../scenes/PreOrderSessionLinesManagement/preOrderSessionLinesManagementContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncPreOrderSessionLinesManagementScene = () => <PreOrderSessionLinesManagementScene />

const ProfileScene = React.lazy(() =>
    import("../scenes/Profile/profileContainer").catch(() => window.location.reload()),
)

export const AsyncProfileScene = () => <ProfileScene />

const ReportsScene = React.lazy(() =>
    import("../scenes/Reports/reportsContainer").catch(() => window.location.reload()),
)

export const AsyncReportsScene = () => <ReportsScene />

const OrderLayout = React.lazy(() => import("./orderLayout").catch(() => window.location.reload()))

export const AsyncOrderLayout = () => <OrderLayout />

const PreOrderLayout = React.lazy(() => import("./preOrderLayout").catch(() => window.location.reload()))

export const AsyncPreOrderLayout = () => <PreOrderLayout />

const PreOrderSessionConfirmationScene = React.lazy(() =>
    import("../scenes/PreOrderSessionConfirmation/preOrderSessionConfirmationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncPreOrderSessionConfirmationScene = () => <PreOrderSessionConfirmationScene />

const PreOrderSessionInitScene = React.lazy(() =>
    import("../scenes/PreOrderSessionInit/preOrderSessionInitContainer").catch(() => window.location.reload()),
)

export const AsyncPreOrderSessionInitScene = () => <PreOrderSessionInitScene />

const PreOrderSessionParticipationScene = React.lazy(() =>
    import("../scenes/PreOrderSessionParticipation/preOrderSessionParticipationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncPreOrderSessionParticipationScene = () => <PreOrderSessionParticipationScene />

const PreOrderSessionControlScene = React.lazy(() =>
    import("../scenes/PreOrderSessionControl/preOrderSessionControlContainer").catch(() => window.location.reload()),
)

export const AsyncPreOrderSessionControlScene = () => <PreOrderSessionControlScene />

const PreOrderSessionCommunicationScene = React.lazy(() =>
    import("../scenes/PreOrderSessionCommunication/preOrderSessionCommunicationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncPreOrderSessionCommunicationScene = () => <PreOrderSessionCommunicationScene />

const PreOrderSessionDeliveryScene = React.lazy(() =>
    import("../scenes/PreOrderSessionDelivery/preOrderSessionDeliveryContainer").catch(() => window.location.reload()),
)

export const AsyncPreOrderSessionDeliveryScene = () => <PreOrderSessionDeliveryScene />

const NotFoundScene = React.lazy(() =>
    import("../scenes/NotFound/notFoundContainer").catch(() => window.location.reload()),
)

export const AsyncNotFoundScene = () => <NotFoundScene />

const ResetPasswordScene = React.lazy(() =>
    import("../shared-components/scenes/ResetPassword/resetPasswordContainer").catch(() => window.location.reload()),
)

export const AsyncResetPasswordScene = () => (
    <ResetPasswordScene
        areaType={AREA_TYPE}
        errorMessage={getChangePasswordErrorMessage}
        getResetPasswordDisplaySuccessMessage={getResetPasswordDisplaySuccessMessage}
        getResetPasswordScore={getResetPasswordScore}
        isFetching={isFetchingChangePassword}
        loginRoute={PagesArchitecture[Page.LOGIN].route}
        passwordRequestKeyName={RequestInfo[CommonRequestKeys.CHANGE_PASSWORD].name}
        resetPasswordPage={Page.RESET_PASSWORD}
    />
)

const HomeScene = React.lazy(() => import("../scenes/Home/homeContainer").catch(() => window.location.reload()))

export const AsyncHomeScene = () => <HomeScene />

const UnauthorizedScene = React.lazy(() =>
    import("../scenes/Unauthorized/unauthorizedContainer").catch(() => window.location.reload()),
)

export const AsyncUnauthorizedScene = () => <UnauthorizedScene />

const TechnicalErrorScene = React.lazy(() =>
    import("../shared-components/components/ErrorBoundary/errorBoundary").catch(() => window.location.reload()),
)

export const AsyncTechnicalErrorScene = () => (
    <TechnicalErrorScene
        page={Page.TECHNICAL_ERROR}
        redirectToHomePage={() => history.push(PagesArchitecture[Page.HOME].route)}
    />
)

const SiretInformationScene = React.lazy(() =>
    import("../scenes/SiretInformation/siretInformationContainer").catch(() => window.location.reload()),
)

export const AsyncSiretInformationScene = () => <SiretInformationScene />

const ContactInformationScene = React.lazy(() =>
    import("../scenes/ContactInformation/contactInformationContainer").catch(() => window.location.reload()),
)

export const AsyncContactInformationScene = () => <ContactInformationScene />

const BeneficiariesCommandScene = React.lazy(() =>
    import("../scenes/BeneficiariesOrder/beneficiariesOrderContainer").catch(() => window.location.reload()),
)

export const AsyncBeneficiariesCommandScene = () => <BeneficiariesCommandScene />

const OrderScene = React.lazy(() => import("../scenes/Order/orderContainer").catch(() => window.location.reload()))

export const AsyncOrderScene = () => <OrderScene />

const OrderOverviewScene = React.lazy(() =>
    import("../scenes/OrderOverview/orderOverviewContainer").catch(() => window.location.reload()),
)

export const AsyncOrderOverviewScene = () => <OrderOverviewScene />

const OrderPaymentScene = React.lazy(() =>
    import("../scenes/OrderPayment/orderPaymentContainer").catch(() => window.location.reload()),
)

export const AsyncOrderPaymentScene = () => <OrderPaymentScene />
