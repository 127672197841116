import {
    SAVE_ORDER_OVERVIEW,
    CLEAR_ORDER_OVERVIEW,
} from "../../../actions/localStorage/orderOverview/orderOverviewActionTypes"

const orderOverViewReducer = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ORDER_OVERVIEW:
            return {
                mailOffers: action.mailOffers,
            }
        case CLEAR_ORDER_OVERVIEW:
            return {}
        default:
            return state
    }
}

export default orderOverViewReducer

/**
 * Return order overview state
 */
export const getOrderOverview = state => state
