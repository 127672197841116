import {combineReducers} from "redux";
import {
    CLEAR_PREORDER_SESSION_LINES_PAGINATION,
    CLEAR_PREORDER_SESSION_LINES_SEARCH_PARAMS,
    GET_PREORDER_SESSION_LINES_FAILURE,
    GET_PREORDER_SESSION_LINES_REQUEST,
    GET_PREORDER_SESSION_LINES_SUCCESS,
    GET_PREORDER_SESSION_ORDERS_FAILURE,
    GET_PREORDER_SESSION_ORDERS_REQUEST,
    GET_PREORDER_SESSION_ORDERS_SUCCESS,
    GET_PREORDER_SESSION_STATISTICS_FAILURE,
    GET_PREORDER_SESSION_STATISTICS_REQUEST,
    GET_PREORDER_SESSION_STATISTICS_SUCCESS,
    SAVE_PREORDER_SESSION_LINES_SEARCH_PARAMS,
} from "../../../actions/entities/preOrderSessions/preOrderSessionsActionTypes";
import {omit} from "lodash";

/**
 * Reducer to stock information about preorder sessions which are not in the entity part of the store.
 */
export const preOrderSessions = () => {
    /**
     * Statistics about preorder sessions.
     */
    const statistics = (state = {}, action = {}) => {
        switch (action.type) {
            case GET_PREORDER_SESSION_STATISTICS_REQUEST:
            case GET_PREORDER_SESSION_STATISTICS_FAILURE:
                return omit(state, action.sessionId);

            case GET_PREORDER_SESSION_STATISTICS_SUCCESS:
                return {
                    ...state,
                    [action.sessionId]: {
                        values: [...action.response],
                        id: action.sessionId,
                    },
                };

            default:
                return state;
        }
    };

    /**
     * Orders linked to preorder sessions.
     */
    const orders = (state = {}, action = {}) => {
        switch (action.type) {
            case GET_PREORDER_SESSION_ORDERS_REQUEST:
            case GET_PREORDER_SESSION_ORDERS_FAILURE:
                return omit(state, action.sessionId);

            case GET_PREORDER_SESSION_ORDERS_SUCCESS:
                return {
                    ...state,
                    [action.sessionId]: {
                        ...action.response,
                        id: action.sessionId,
                    },
                };

            default:
                return state;
        }
    };

    /**
     * Pagination on preorder session lines.
     */
    const paginationLines = (state = {}, action = {}) => {
        switch (action.type) {
            case GET_PREORDER_SESSION_LINES_SUCCESS:
                return action.pagination ? action.pagination : {};

            case GET_PREORDER_SESSION_LINES_REQUEST:
            case GET_PREORDER_SESSION_LINES_FAILURE:
            case CLEAR_PREORDER_SESSION_LINES_PAGINATION:
                return {};

            default:
                return state;
        }
    };

    const searchLinesParams = (state = {}, action = {}) => {
        switch (action.type) {
            case SAVE_PREORDER_SESSION_LINES_SEARCH_PARAMS:
                return action.formValues;

            case CLEAR_PREORDER_SESSION_LINES_SEARCH_PARAMS:
                return {};

            default:
                return state;
        }
    };

    return combineReducers({
        statistics,
        orders,
        paginationLines,
        searchLinesParams,
    });
};

/**
 * Find the statistics of a preorder session.
 */
export const getStatisticsById = (state, sessionId) => state.statistics[sessionId];

/**
 * Find the orders linked to a preorder session.
 */
export const getOrdersById = (state, sessionId) => state.orders[sessionId];

/**
 * Return pagination lines information
 */
export const getPaginationLines = state => state.paginationLines;

/**
 * Return values of search lines params
 */
export const getSearchLinesParams = state => state.searchLinesParams;
