import {
    EMPTY_SIRET_VALIDATION,
    SIRET_VALIDATION_FAILURE,
    SIRET_VALIDATION_REQUEST, SIRET_VALIDATION_SUCCESS
} from "../../../actions/temporaries/siretValidation/siretValidationActionTypes";

/**
 * Siret validation reducer.
 */
const siretValidation = (state = {}, action = {}) => {
    switch (action.type) {
        case SIRET_VALIDATION_SUCCESS:
            return {
                knownSiret: action.response.siretConnu,
                clientType: action.response.typeClient,
                interlocutorId: action.response.idInterlocuteur,
            };
        case SIRET_VALIDATION_FAILURE:
        case SIRET_VALIDATION_REQUEST:
        case EMPTY_SIRET_VALIDATION:
            return {};
        default:
            return state;
    }
};

export default siretValidation;

/**
 * Return the details of the siret validation.
 */
export const getDetails = (state) => state;
