import {combineReducers} from "redux"
import {
    CLEAR_COMMUNICATION_VALUES,
    UPDATE_CONFIRMATION_MAIL_CONTENT,
    UPDATE_INTRODUCTION_MAIL_CONTENT,
    UPDATE_OPENING_MAIL_CONTENT,
    UPDATE_CLOSING_MAIL_CONTENT,
    UPDATE_DAYS_BEFORE_CLOSING_MAIL,
} from "../../../actions/localStorage/preOrderSessionCommunication/preOrderSessionCommunicationActionTypes"
import {PREORDER_SESSION_INFORMATION_SUCCESS} from "../../../shared-components/actions/entities/preOrderSessions/preOrderSessionsActionTypes"

const preOrderSessionCommunication = (state = {}, action = {}) => {
    const confirmationMailContent = (state = "", action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return ""
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.confirmationMailContent
            case UPDATE_CONFIRMATION_MAIL_CONTENT:
                return action.content
            default:
                return state
        }
    }
    const introductionMailContent = (state = "", action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return ""
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.introductionMailContent
            case UPDATE_INTRODUCTION_MAIL_CONTENT:
                return action.content
            default:
                return state
        }
    }
    const openingSessionMailContent = (state = "", action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return ""
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.openingSessionMailContent
            case UPDATE_OPENING_MAIL_CONTENT:
                return action.content
            default:
                return state
        }
    }
    const closingSessionMailContent = (state = "", action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return ""
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.closingSessionMailContent
            case UPDATE_CLOSING_MAIL_CONTENT:
                return action.content
            default:
                return state
        }
    }

    const daysBeforeClosingMail = (state = null, action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return null
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.daysBeforeClosingMail
            case UPDATE_DAYS_BEFORE_CLOSING_MAIL:
                return action.days
            default:
                return state
        }
    }

    const displayIntroductionMessage = (state = null, action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return null
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.displayIntroductionMessage
            default:
                return state
        }
    }

    const displayConfirmationMessage = (state = null, action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return null
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.displayConfirmationMessage
            default:
                return state
        }
    }

    const sendOpeningMail = (state = null, action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return null
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.sendOpeningMail
            default:
                return state
        }
    }

    const sendMailBeforeClosingDate = (state = null, action = {}) => {
        switch (action.type) {
            case CLEAR_COMMUNICATION_VALUES:
                return null
            case PREORDER_SESSION_INFORMATION_SUCCESS:
                return action.communicationValues.sendMailBeforeClosingDate
            default:
                return state
        }
    }

    return combineReducers({
        confirmationMailContent,
        introductionMailContent,
        openingSessionMailContent,
        closingSessionMailContent,
        daysBeforeClosingMail,
        displayIntroductionMessage,
        displayConfirmationMessage,
        sendOpeningMail,
        sendMailBeforeClosingDate,
    })
}

export default preOrderSessionCommunication

export const getPreOrderSessionCommunication = state => state
