/**
 * Enumeration for segment types.
 */
export const SegmentTypes = {
    GRANDE_ENTREPRISE: "1",
    COLLECTIVITE: "2",
    COMITE_ENTREPRISE: "3",
    PROFESSION_LIBERALE: "4",
    PME: "5",
    TPE: "6",
    MUTUELLE_RETRAITE_PREVOYANCE: "7",
}
