import {
    CLEAR_PARTICIPATION_VALUES,
    SAVE_PARTICIPATION_VALUES,
} from "../../../actions/localStorage/preOrderSessionParticipation/preOrderSessionParticipationActionTypes"
import {PREORDER_SESSION_INFORMATION_SUCCESS} from "../../../shared-components/actions/entities/preOrderSessions/preOrderSessionsActionTypes"

const preOrderSessionParticipation = (state = {}, action = {}) => {
    switch (action.type) {
        case PREORDER_SESSION_INFORMATION_SUCCESS:
            return action.participationValues
        case SAVE_PARTICIPATION_VALUES:
            return action.values
        case CLEAR_PARTICIPATION_VALUES:
            return {}
        default:
            return state
    }
}

export default preOrderSessionParticipation

export const getPreOrderSessionParticipation = state => state
