import {CLEAR_PARTICIPATION_VALUES, SAVE_PARTICIPATION_VALUES} from "./preOrderSessionParticipationActionTypes"

/**
 * Action to save pre order participation values
 */
export const savePreOrderParticipationValues = values => ({
    type: SAVE_PARTICIPATION_VALUES,
    values,
})

/**
 * Action to clear pre order participation values
 */
export const clearPreOrderParticipationValues = () => ({
    type: CLEAR_PARTICIPATION_VALUES,
})
