/**
 * The pages of the application.
 */
export const Page = {
    BENEFICIARIES: "BENEFICIARIES",
    BENEFICIARY_STATS: "BENEFICIARY_STATS",
    BYPASS_LOGIN: "BYPASS_LOGIN",
    GENERAL_DATA: "GENERAL_DATA",
    HOME: "HOME",
    INTERLOCUTOR_ADMINISTRATION: "INTERLOCUTOR_ADMINISTRATION",
    INTERLOCUTOR_ACCOUNT_ACCESS: "INTERLOCUTOR_ACCOUNT_ACCESS",
    INTERVENANT_SEARCH: "INTERVENANT_SEARCH",
    INTERVENANT_STATS: "INTERVENANT_STATS",
    LEGAL_NOTICE: "LEGAL_NOTICE",
    LOGIN: "LOGIN",
    ASK_FOR_HELP: "ASK_FOR_HELP",
    ASK_FOR_HELP_CONNECTED: "ASK_FOR_HELP_CONNECTED",
    MANAGEMENT_REQUEST: "MANAGEMENT_REQUEST",
    MANAGEMENT_REQUESTS_LIST: "MANAGEMENT_REQUESTS_LIST",
    ORDER_BENEFICIARIES: "ORDER_BENEFICIARIES",
    ORDER_CONTACT_INFORMATION: "ORDER_CONTACT_INFORMATION",
    ORDER_CONFIRMATION: "ORDER_CONFIRMATION",
    ORDER_DEPOSIT: "ORDER_DEPOSIT",
    ORDER_DEPOSIT_HISTORY: "ORDER_DEPOSIT_HISTORY",
    ORDER_HISTORY: "ORDER_HISTORY",
    ORDER_ORDER: "ORDER_ORDER",
    ORDER_OVERVIEW: "ORDER_OVERVIEW",
    ORDER_PAYMENT: "ORDER_PAYMENT",
    ORDER_SIRET_INFORMATION: "ORDER_SIRET_INFORMATION",
    PREORDER_SESSION_COMMUNICATION: "PREORDER_SESSION_COMMUNICATION",
    PREORDER_SESSION_CONFIRMATION: "PREORDER_SESSION_CONFIRMATION",
    PREORDER_SESSION_DELIVERY: "PREORDER_SESSION_DELIVERY",
    PREORDER_SESSION_CONTROL: "PREORDER_SESSION_CONTROL",
    PREORDER_SESSION_INIT: "PREORDER_SESSION_INIT",
    PREORDER_SESSION_LINES_MANAGEMENT: "PREORDER_SESSION_LINES_MANAGEMENT",
    PREORDER_SESSION_MANAGEMENT: "PREORDER_SESSION_MANAGEMENT",
    PREORDER_SESSION_PARTICIPATION: "PREORDER_SESSION_PARTICIPATION",
    PREORDER_SESSION_SUM_UP: "PREORDER_SESSION_SUM_UP",
    PREORDER_SESSION_EDIT_INIT: "PREORDER_SESSION_EDIT_INIT",
    PREORDER_SESSION_EDIT_PARTICIPATION: "PREORDER_SESSION_EDIT_PARTICIPATION",
    PREORDER_SESSION_EDIT_CONTROL: "PREORDER_SESSION_EDIT_CONTROL",
    PREORDER_SESSION_EDIT_DELIVERY: "PREORDER_SESSION_EDIT_DELIVERY",
    PREORDER_SESSION_EDIT_COMMUNICATION: "PREORDER_SESSION_EDIT_COMMUNICATION",
    PROFILE: "PROFILE",
    RECIPIENTS: "RECIPIENTS",
    RECIPIENT_STATS: "RECIPIENT_STATS",
    RECOVER_LOGIN_PASSWORD: "RECOVER_LOGIN_PASSWORD",
    REPORTS: "REPORTS",
    REQUEST_MANAGEMENT: "REQUEST_MANAGEMENT",
    RESET_PASSWORD: "RESET_PASSWORD",
    STYLEGUIDE: "STYLEGUIDE",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
    UNAUTHORIZED: "UNAUTHORIZED",
}
