import {
    CLEAR_DELIVERY_VALUES,
    SAVE_DELIVERY_VALUES,
} from "../../../actions/localStorage/preOrderSessionDelivery/preOrderSessionDeliveryActionTypes"
import {PREORDER_SESSION_INFORMATION_SUCCESS} from "../../../shared-components/actions/entities/preOrderSessions/preOrderSessionsActionTypes"

export const preOrderSessionDelivery = (state = {}, action = {}) => {
    switch (action.type) {
        case PREORDER_SESSION_INFORMATION_SUCCESS:
            return action.deliveryValues
        case SAVE_DELIVERY_VALUES:
            return action.values
        case CLEAR_DELIVERY_VALUES:
            return {}
        default:
            return state
    }
}

export default preOrderSessionDelivery

export const getPreOrderSessionDelivery = state => state
